import { useDashboardData } from "hooks";
import { Box, Flex } from "organic";
import colors from "organic/theme/colors";

import React from "react";
import { HEADER_HEIGHT } from "components/layout";
import { TAIHeader } from "./TAIHeader";
import { Splashscreen } from "./splashscreen";
import { TAIMain } from "./TAIMain";

export const TAI = () => {
  const { dashboardState } = useDashboardData();
  const { isTAIActive } = dashboardState.data;
  return (
    <Flex position="relative" height={`calc(100vh - ${HEADER_HEIGHT})`}>
      <Flex
        position="absolute"
        backgroundColor="white"
        borderRadius="8px 10px 10px 8px"
        boxShadow="0px 88.38px 176.77px 0px #3440542E"
        outline={`1px solid ${colors.gray200}`}
        overflow="hidden"
        overflowY="auto"
        gap="2rem"
        flexDirection="column"
        width="395px"
        height="661px"
        top="2rem"
        right="2rem"
        // height="520px"
      >
        <TAIHeader />
        {isTAIActive ? <TAIMain /> : <Splashscreen />}
      </Flex>
      <Box width="395px" />
    </Flex>
  );
};
