import styled, { css } from "styled-components/macro";
import { background, flex, layout, space } from "styled-system";
// import shouldForwardProp from "@styled-system/should-forward-prop";

export const StyledInput = styled.input.withConfig({})`
  background-color: "#d0d5dd";
  border: none;
  outline: none;
  width: 100%;
  padding: 0px 7px;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledInputGroup = styled.div.withConfig({
  // shouldForwardProp,
})`
  ${StyledInput} {
    background-color: "#D0D5DD";
    height: 44px;
    display: inline-flex;
    flex: 1;
  }

  ${props => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
  align-items: center;

  outline: 1px solid ${props.theme.colors.gray300};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
   background-color: "#D0D5DD"


  &:active{
    outline: 1px solid ${props.theme.colors.primary300};

    box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:hover {
    outline: 1px solid ${props.theme.colors.primary300};

    box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  }

  &:disabled {
  background: ${props.theme.colors.gray50};
  border: 1px solid ${props.theme.colors.gray300};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
 cursor: not-allowed;
  }

  &:disabled:hover {
  background: ${props.theme.colors.gray50};
  outline: 1px solid ${props.theme.colors.gray300};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: not-allowed;
  }

  `}

  ${props =>
    props?.error === true
      ? css`
          background-color: ${props.theme.colors.white};
          border: 1px solid ${props.theme.colors.error300};
          &:active {
            border: 1px solid ${props.theme.colors.error300};
            box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }

          &:hover {
            border: 1px solid ${props.theme.colors.error300};
            box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }

          &:disabled {
            background: ${props.theme.colors.gray50};
            border: 1px solid ${props.theme.colors.gray300};
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            cursor: not-allowed;
          }
        `
      : ""}

  ${layout}
`;

export const StyledTextArea = styled.textarea.withConfig({})`
  resize: none;
  width: 100%;
  overflow-y: auto;
`;

export const StyledTextAreaGroup = styled.div.withConfig({})`
  ${props => css`
    border-radius: ${props?.isRounded === true ? "0.5rem" : ""};

    ${StyledTextArea} {
      border: none;
      outline: none;
      padding: 0px;
      margin: 0px;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0%;
      vertical-align: middle;

      min-height: ${props?.minHeight ? props.minHeight : "auto"};
      max-height: ${props?.maxHeight ? props.maxHeight : "auto"};
    }
  `}

  ${props => css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.5rem 1rem;

    border: 1px solid ${props.theme.colors.gray300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background-color: "#D0D5DD";

    min-height: ${props?.minHeight ? props.minHeight : "auto"};
    max-height: ${props?.maxHeight ? props.maxHeight : "auto"};

    &:active {
      border: 1px solid ${props.theme.colors.primary300};

      box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    &:hover {
      border: 1px solid ${props.theme.colors.primary300};

      box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    &:disabled {
      background: ${props.theme.colors.gray50};
      border: 1px solid ${props.theme.colors.gray300};
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      cursor: not-allowed;
    }
  `}


  ${props =>
    props.error === true
      ? css`
          background-color: ${props.theme.colors.white};
          border: 1px solid ${props.theme.colors.error300};
          &:active {
            border: 1px solid ${props.theme.colors.error300};
            box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }

          &:hover {
            border: 1px solid ${props.theme.colors.error300};
            box-shadow: 0px 0px 0px 4px rgba(245, 255, 241, 1);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }

          &:disabled {
            background: ${props.theme.colors.gray50};
            border: 1px solid ${props.theme.colors.gray300};
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            cursor: not-allowed;
          }
        `
      : ""}

      ${layout}
      ${space}
      ${background}
      ${flex}
`;

export const StyledLabel = styled.span`
  line-height: 20px;
  font-size: 14px;
  color: #344054;
  padding-bottom: 3px;
`;

//  ${props.theme.colors.white};
