import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { toast } from "react-toastify";
import SingleDatePicker from "components/v1/DatePicker/CustomDatePicker";
import OverlayLoader from "components/v1/Loader/OverlayLoader";
import seasonApi from "api/season";
import { useDispatch } from "react-redux";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
// import CustomModal from "components/v1/Modal/CustomModal";
import { getAllSeasons } from "features/v1/season";
import { Box, Button, Flex, Input, Label, Text } from "organic";
import { FormInput } from "components/inputs";
const EditProjectDetails = props => {
  const { selected, modal } = props;

  const dispatch = useDispatch();

  const handleSeasonEdit = (values, { formOptions, modal: _modal }) => {
    let id = values._id;
    if (values.achieved === 0) {
      values.achieved = 1;
    }
    if (values.target === 0) {
      values.target = 1;
    }
    let sanitizedSeason = sanitizeMongoDoc(values, ["regions"]);
    return seasonApi
      .put(`/${id}`, sanitizedSeason)
      .then(response => {
        if (response.status === 200) {
          toast.success("Season edited successfully!");
        }
        if (response.status === 401) {
          toast.error("Edit Failed! You are not authorized to do that!");
        }
        formOptions.setSubmitting(false);
        _modal.hideModal();
        return dispatch(getAllSeasons());
      })
      .catch(__err => {
        toast.error("an error occured, Edit Failed");

        formOptions.setSubmitting(false);
        _modal.hideModal();
      });
  };

  return (
    <Formik
      initialValues={selected}
      onSubmit={(values, formOptions) => {
        dispatch(handleSeasonEdit(values, { formOptions, modal, selected }));
      }}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => (
        <Box>
          <OverlayLoader active={isSubmitting}>
            <Form>
              <Text mx={2} color="red" textAlign="center">
                {errors.message}
              </Text>

              <Box my={40}>
                <Box mb={4}>
                  <FormInput label="Project Name" name="name" defaultValue={selected.name} />
                  <Box my={3}>
                    <FormInput
                      name="target"
                      label="Project's Farmer Onboarding Target"
                      type="number"
                      defaultValue={selected.target}
                    />
                  </Box>
                  <Box mb={2}>
                    <FormInput
                      label="Porject's Farmer Target acheived"
                      name="achieved"
                      defaultValue={selected.achieved}
                      type="number"
                      disabled
                    />
                  </Box>

                  <Box my={4}>
                    <Label check>
                      <Text as="label">Season Active*</Text>
                      <br />
                      <Input
                        type="checkbox"
                        checked={values.isActive}
                        onChange={__e => {
                          setFieldValue("isActive", !values.isActive);
                        }}
                      />
                      &nbsp;Active
                    </Label>
                  </Box>
                  <Flex style={{ gap: "10px" }}>
                    {" "}
                    <Box mb={2}>
                      <Text as="p">Start Date*</Text>
                      <SingleDatePicker
                        date={new Date(values.duration.start_date)}
                        handleDateChange={date => {
                          if (date !== null) {
                            // setNewStartDate(date);
                            setFieldValue("duration.start_date", date.toISOString());
                          }
                        }}
                      />

                      <ErrorMessage name="duration.start_date" component="p" className="text-danger" />
                    </Box>
                    <Box mb={2}>
                      <Text as="p">End Date*</Text>
                      <SingleDatePicker
                        date={new Date(values.duration.end_date)}
                        handleDateChange={date => {
                          if (date !== null) {
                            // setNewEndDate(date);
                            setFieldValue("duration.end_date", date.toISOString());
                          }
                        }}
                      />
                      <ErrorMessage name="duration.end_date" component="p" className="text-danger" />
                    </Box>
                  </Flex>

                  <Flex mt="2rem" gap="20px">
                    <Button type="button" colorScheme="secondaryGray" onClick={modal.hideModal} disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      disabled={isSubmitting || Object.keys(errors).length > 1}
                    >
                      Edit Project
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Form>
          </OverlayLoader>
        </Box>
      )}
    </Formik>
  );
};

export default EditProjectDetails;
