// import { getApiUrl } from "utils";

import { getApiUrl } from "utils";
import { axiosService } from "../fetchService";
import { apiConstants } from "constants/apiConstants";

export default class Auth_API {
  static async login(data, __config) {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.admin, path: "/login" });

    return await axiosService({ url, data, method: "POST" });
  }

  static async fetchUser(__config) {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.admin, path: "/find_one" });
    return await axiosService({ method: "GET", url });
  }
}
