import React, { useState } from "react";
import { Box } from "organic";
import { Table } from "components/table";
import { Modal, ModalContent } from "components/modal";
import { useDisclosure } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  farmerApprovedPaymentRequestStatus,
  farmerPaymentRequestStatus,
  getAllApprovedPaymentRequestsWithParams,
  getAllFarmerPaymentsWithParams,
  getFarmerPaymentDateRange,
  getLoadingFarmerPayment,
  searchAllFarmerPaymentsWithParams,
  updateBulkFarmerPayment,
  updateFarmerPayment,
} from "features/v1/farmerPayment";
import EditPaymentsInfo from "./editPaymentsInfo";
import { financePage } from "routes/dashboardRoutes";
import { ConfirmDialog, RejectWithCommentDialog } from "components/confirmDialog";
import { useNavigate } from "react-router-dom";
import { useInitiateFarmerPayment } from "hooks/app/finance/useInitiateFarmerPayout";
import { handleFormSubmission } from "services/formSubmissionService";
import { toast } from "react-toastify";
import { AOSClientError } from "services/shared/AOSErrorService";
import { useUserData } from "hooks/app";

const PaymentsTable = ({ tableProps, selectedPayment, selectedCropType, activeTab, page }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(getLoadingFarmerPayment);

  const { mutateAsync: initiateFarmerPayout, isLoading: isInitiatingFarmerPayout } = useInitiateFarmerPayment();

  const isPaymentsPage = page === financePage.PAYMENT;
  const [rejectPaymentRequestComment, setRejectPaymentRequestComment] = useState("");
  const [declinePaymentComment, setDeclinePaymentComment] = useState("");
  const [bulkDeclinePaymentComment, setBulkDeclinePaymentComment] = useState("");

  const [bulkAction, setBulkAction] = useState({ selected: [], actionKey: "" });

  const { first_name, last_name } = useUserData();

  const farmerEditModal = useDisclosure();
  const rejectionCommentModal = useDisclosure();
  const confirmPayoutModal = useDisclosure();
  const declinePaymentModal = useDisclosure();
  // bulk modals
  const bulkDeclinePaymentModal = useDisclosure();
  const bulkConfirmPaymentModal = useDisclosure();

  let paymentAdditionalParams = {};
  let managerAdditionalParams = {};

  switch (activeTab) {
    case "tab1":
      isPaymentsPage ? (paymentAdditionalParams = {}) : (managerAdditionalParams = {});
      break;
    case "tab2":
      isPaymentsPage
        ? (paymentAdditionalParams = { status: farmerPaymentRequestStatus.APPROVED })
        : (managerAdditionalParams = { payment_status: farmerApprovedPaymentRequestStatus.PROCESSED });
      break;
    case "tab3":
      isPaymentsPage
        ? (paymentAdditionalParams = { status: farmerPaymentRequestStatus.REJECTED })
        : (managerAdditionalParams = { payment_status: farmerApprovedPaymentRequestStatus.DECLINED });
      break;
    case "tab4":
      isPaymentsPage
        ? (paymentAdditionalParams = { status: farmerPaymentRequestStatus.PENDING })
        : (managerAdditionalParams = { payment_status: farmerApprovedPaymentRequestStatus.PENDING });
      break;
    default:
      isPaymentsPage ? (paymentAdditionalParams = {}) : (managerAdditionalParams = {});
  }

  const navigateToPage = (route, tab) => {
    navigate({
      pathname: route,
      search: `?tab=${tab}`,
    });
  };

  /** determine what extra action are avalable */
  const getExtraActions = payment => {
    let paymentExtraActions = [
      // { label: "Edit details", actionKey: "editDetails" },
      { label: "Approve", actionKey: "approve" },
      { label: "Reject", actionKey: "reject" },
    ];

    let managerExtraActions = [
      isInitiatingFarmerPayout ? undefined : { label: "Payout", actionKey: "payout" },
      { label: "Decline", actionKey: "decline" },
    ];

    paymentExtraActions =
      payment.status === farmerPaymentRequestStatus.APPROVED
        ? paymentExtraActions.filter(action => action.actionKey !== "approve")
        : payment.status === farmerPaymentRequestStatus.REJECTED
        ? paymentExtraActions.filter(action => action.actionKey !== "reject")
        : paymentExtraActions;

    managerExtraActions =
      payment.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED
        ? []
        : payment.payment_status === farmerApprovedPaymentRequestStatus.DECLINED
        ? managerExtraActions.filter(action => action.actionKey !== "decline")
        : managerExtraActions;

    return isPaymentsPage ? paymentExtraActions : managerExtraActions;
  };

  /** determine what multiselect or bulk actions are available */
  const getMultiSelectActions = () => {
    let paymentExtraActions =
      isPaymentsPage && activeTab === "tab2"
        ? [{ label: "Reject", actionKey: "reject" }]
        : isPaymentsPage && activeTab === "tab3"
        ? [{ label: "Approve", actionKey: "approve" }]
        : isPaymentsPage && activeTab === "tab4"
        ? [
            { label: "Approve", actionKey: "approve" },
            { label: "Reject", actionKey: "reject" },
          ]
        : [];

    let managerExtraActions =
      !isPaymentsPage && activeTab === "tab4"
        ? [
            { label: "Payout", actionKey: "payout" },
            { label: "Decline", actionKey: "decline" },
          ]
        : !isPaymentsPage && activeTab === "tab3"
        ? [{ label: "Payout", actionKey: "payout" }]
        : [];

    return isPaymentsPage ? paymentExtraActions : managerExtraActions;
  };

  const approveFarmerPayment = (id, type, modal) => {
    dispatch(
      updateFarmerPayment(
        { status: `${type === "approve" ? farmerPaymentRequestStatus.APPROVED : farmerPaymentRequestStatus.REJECTED}` },
        {
          type,
          farmerPaymentId: selectedPayment._id,
          tradeId: selectedPayment.trade_id,
          unit: selectedPayment.unit,
          modal,
          setActiveTab: () => navigateToPage("", "tab2"),
        }
      )
    );
  };

  const processPayout = async (payment, type) => {
    handleFormSubmission({
      submitFn: initiateFarmerPayout,
      values: [
        {
          id: payment._id,
          farmer_id: payment.farmer_id,
          farmer: payment.farmer._id,
          agent: payment.agent,
          ta_number: payment.ta_number,
          trade_id: payment.trade_id,
          accountNumber: payment.accountNumber,
          amount: payment.amount,
          season: payment.season,
          Remarks: "Approved Farmer Payout",
          Occasion: "Approved Farmer Payout",
        },
      ],
      onSuccess() {
        dispatch(
          updateFarmerPayment(
            { payment_status: farmerApprovedPaymentRequestStatus.PROCESSED },
            {
              type,
              farmerPaymentId: payment._id,
              tradeId: payment.trade_id,
              unit: payment.unit,
              setActiveTab: () => navigateToPage("", "tab2"),
            }
          )
        );
      },
      onError(err) {
        toast.error("Unable to complete payout, kindly try again");
        const bug = new AOSClientError("Failed to complete farmer payout");
        bug.report(err);
      },
    });
  };
  const processBulkAction = () => {
    let updatePayload = [];
    let paymentPayload = [];

    if (bulkAction.actionKey === "approve") {
      updatePayload = bulkAction.selected.map(item => ({
        farmerPaymentId: item._id,
        status: farmerPaymentRequestStatus.APPROVED,
      }));
      dispatch(
        updateBulkFarmerPayment(updatePayload, {
          type: bulkAction.actionKey,
          setActiveTab: () => navigateToPage("", "tab2"),
        })
      );
      return;
    }

    if (bulkAction.actionKey === "payout") {
      paymentPayload = bulkAction.selected.map(item => ({
        id: item._id,
        farmer_id: item.farmer_id,
        farmer: item.farmer,
        agent: item.angent,
        ta_number: item.ta_number,
        trade_id: item.trade_id,
        accountNumber: item.accountNumber,
        amount: item.amount,
        season: item.season,
        Remarks: "Approved Farmer Pauyout",
        Occasion: "Approved Farmer Payout",
      }));
      handleFormSubmission({
        submitFn: initiateFarmerPayout,
        values: paymentPayload,
        onSuccess() {
          updatePayload = bulkAction.selected.map(item => {
            return {
              farmerPaymentId: item._id,
              payment_status: farmerApprovedPaymentRequestStatus.PROCESSED,
              status: farmerPaymentRequestStatus.APPROVED,
              comment: `Approved by ${first_name} ${last_name}`,
            };
          });
          dispatch(updateBulkFarmerPayment(updatePayload));
        },
        onError(err) {
          toast.error("Unable to complete payout, kindly try again");
          const bug = new AOSClientError("Failed to complete farmer payout");
          bug.report(err);
        },
      });
      // dispatch(
      //   updateBulkFarmerPayment(updatePayload, {
      //     type: bulkAction.actionKey,
      //     resetForm: () => setRejectPaymentRequestComment(""),
      //     setActiveTab: () => navigateToPage("", "tab2"),
      //     modal: bulkDeclinePaymentModal.modal,
      //   })
      // );
      return;
    }
    if (bulkAction.actionKey === "reject") {
      updatePayload = bulkAction.selected.map(item => ({
        farmerPaymentId: item._id,
        status: farmerPaymentRequestStatus.REJECTED,
        comment: bulkDeclinePaymentComment,
      }));
      dispatch(
        updateBulkFarmerPayment(updatePayload, {
          type: bulkAction.actionKey,
          resetForm: () => setBulkDeclinePaymentComment(""),
          setActiveTab: () => navigateToPage("", "tab3"),
          modal: bulkDeclinePaymentModal.modal,
        })
      );
      return;
    }
    if (bulkAction.actionKey === "decline") {
      updatePayload = bulkAction.selected.map(item => ({
        farmerPaymentId: item._id,
        payment_status: farmerApprovedPaymentRequestStatus.DECLINED,
        comment: bulkDeclinePaymentComment,
        status: item.status,
      }));
      dispatch(
        updateBulkFarmerPayment(updatePayload, {
          type: bulkAction.actionKey,
          setActiveTab: () => navigateToPage("", "tab3"),
          resetForm: () => setBulkDeclinePaymentComment(""),
        })
      );
      return;
    }

    console.log({ updatePayload });
  };

  return (
    <>
      <Box>
        <Table
          {...tableProps}
          onSearch={(params, { abortController }) => {
            isPaymentsPage
              ? dispatch(
                  searchAllFarmerPaymentsWithParams(
                    { ...params, ...paymentAdditionalParams },
                    { abortController } // cancel token
                  )
                )
              : dispatch(
                  searchAllFarmerPaymentsWithParams(
                    { ...params, status: farmerPaymentRequestStatus.APPROVED, ...managerAdditionalParams },
                    { abortController } // cancel token
                  )
                );
          }}
          // eslint-disable-next-line no-unused-vars
          onDataFilter={({ seasonId, region, dateRange, nPerPage, page }) => {
            dispatch(getFarmerPaymentDateRange(dateRange.map(d => new Date(d).toISOString())));
            isPaymentsPage
              ? dispatch(
                  getAllFarmerPaymentsWithParams({
                    nPerPage,
                    page,
                    dateRange,
                    crop_type: selectedCropType?.value,
                    ...paymentAdditionalParams,
                  })
                )
              : dispatch(
                  getAllApprovedPaymentRequestsWithParams({
                    nPerPage,
                    page,
                    dateRange,
                    crop_type: selectedCropType?.value,
                    status: farmerPaymentRequestStatus.APPROVED,
                    ...managerAdditionalParams,
                  })
                );
          }}
          onExtraAction={({ actionKey }) => {
            if (actionKey === "editDetails") {
              farmerEditModal.onOpen();
            }
            if (actionKey === "approve") {
              approveFarmerPayment(selectedPayment._id, "approve");
            }
            if (actionKey === "reject") {
              rejectionCommentModal.onOpen();
            }
            if (actionKey === "payout") {
              confirmPayoutModal.onOpen();
            }
            if (actionKey === "decline") {
              declinePaymentModal.onOpen();
            }
          }}
          getExtraActions={getExtraActions}
          getMultiSelectActions={getMultiSelectActions}
          onMultiSelect={({ actionKey, selected }) => {
            setBulkAction({ actionKey, selected });

            if (actionKey === "approve" || actionKey === "payout") {
              bulkConfirmPaymentModal.onOpen();
            }

            if (actionKey === "reject" || actionKey === "decline") {
              bulkDeclinePaymentModal.onOpen();
            }
          }}
        />
      </Box>
      <Modal
        open={farmerEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmerEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Payments  Information">
          <EditPaymentsInfo selected={selectedPayment} modal={farmerEditModal.modal} />
        </ModalContent>
      </Modal>
      <Modal
        open={rejectionCommentModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            rejectionCommentModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={650}>
          <RejectWithCommentDialog
            loading={loading}
            comment={rejectPaymentRequestComment}
            setComment={setRejectPaymentRequestComment}
            selected={selectedPayment}
            modal={rejectionCommentModal.modal}
            handleSubmit={() => {
              dispatch(
                updateFarmerPayment(
                  { status: farmerPaymentRequestStatus.REJECTED, comment: rejectPaymentRequestComment },
                  {
                    type: "reject",
                    farmerPaymentId: selectedPayment._id,
                    tradeId: selectedPayment.trade_id,
                    unit: selectedPayment.unit,
                    modal: rejectionCommentModal.modal,
                    resetForm: () => setRejectPaymentRequestComment(""),
                    setActiveTab: () => navigateToPage("", "tab3"),
                  }
                )
              );
            }}
          />
        </ModalContent>
      </Modal>
      <Modal
        open={confirmPayoutModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            confirmPayoutModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={550}>
          <ConfirmDialog
            heading="Do you want to continue?"
            subHeading="<p>
            Are you sure you want to confirm payout? <br /> This action cannot be reversed
          </p>"
            confirmationText="Confirm"
            modal={confirmPayoutModal.modal}
            onConfirm={() => {
              processPayout(selectedPayment, "payout");
              confirmPayoutModal.modal.hideModal();
            }}
          />
        </ModalContent>
      </Modal>
      <Modal
        open={declinePaymentModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            declinePaymentModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={650}>
          <RejectWithCommentDialog
            loading={loading}
            comment={declinePaymentComment}
            setComment={setDeclinePaymentComment}
            selected={selectedPayment}
            modal={declinePaymentModal.modal}
            handleSubmit={() => {
              dispatch(
                updateFarmerPayment(
                  { payment_status: farmerApprovedPaymentRequestStatus.DECLINED, comment: declinePaymentComment },
                  {
                    type: "Declined",
                    farmerPaymentId: selectedPayment._id,
                    tradeId: selectedPayment.trade_id,
                    unit: selectedPayment.unit,
                    modal: declinePaymentModal.modal,
                    resetForm: () => setDeclinePaymentComment(""),
                    setActiveTab: () => navigateToPage("", "tab3"),
                  }
                )
              );
            }}
          />
        </ModalContent>
      </Modal>
      {/* BULK MODALS */}
      <Modal {...bulkConfirmPaymentModal.modalProps}>
        <ModalContent variant="center" width={550}>
          <ConfirmDialog
            heading="Do you want to continue Bulk Operation?"
            subHeading={`<p>
            Are you sure you want to confirm ${bulkAction.actionKey} of <em>${bulkAction?.selected?.length} payments</em>? <br /> This action cannot be reversed
          </p>`}
            confirmationText="Confirm"
            modal={bulkConfirmPaymentModal.modal}
            onConfirm={() => {
              processBulkAction();
              bulkConfirmPaymentModal.modal.hideModal();
            }}
          />
        </ModalContent>
      </Modal>
      <Modal {...bulkDeclinePaymentModal.modalProps}>
        <ModalContent variant="center" width={650}>
          <RejectWithCommentDialog
            loading={loading}
            comment={bulkDeclinePaymentComment}
            setComment={setBulkDeclinePaymentComment}
            modal={bulkDeclinePaymentModal.modal}
            handleSubmit={() => {
              processBulkAction();
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentsTable;
