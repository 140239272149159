import React, { useState } from "react";
import { Flex, Text, Button, Icon, organicTheme } from "organic";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
// import CustomFormField from "components/v1/Forms/CustomFormField";
import { FormInput } from "components/inputs";
import { loginValidator } from "validators/authValidators";
import { ErrorState } from "components/appState";
import { AOSClientError } from "services/shared/AOSErrorService";
import Auth_API from "services/api/authApiService";
import { setAuthCredentials } from "services/shared/authService";
import { toast } from "react-toastify";
import { routeNames } from "routes/dashboardRoutes";

export default function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (values, { setErrors, setSubmitting }) => {
    try {
      setIsLoading(true);
      const res = await Auth_API.login(values);
      console.log({ res });
      const _loginData = res.data.data;
      const { accessToken, refreshToken, scope } = res.data.data.sessionAuth;
      setAuthCredentials({ token: accessToken, refresh: refreshToken, organization: _loginData.organization, scope });
      toast.success(res?.data?.message?.description || res?.data?.message || "Welcome back");
      setIsLoading(false);
      navigate(routeNames.dashboardBaseUrl);
    } catch (loginError) {
      console.log({ loginError });
      setIsLoading(false);
      setErrors({
        message:
          loginError?.response?.data?.message ||
          loginError?.response?.data?.error ||
          loginError?.response?.data ||
          loginError.message ||
          "Could not login, kindly try again",
      });
      setSubmitting(false);
      const bug = new AOSClientError("Login Failed");
      bug.report();
    }
  };
  return (
    <ErrorState>
      <Formik
        initialValues={{ phone_number: "", password: "" }}
        validationSchema={loginValidator}
        // validateOnChange
        onSubmit={handleLogin}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <Text font="displaySmSemibold" color="gray900">
              Hi, Welcome back!
            </Text>
            <Text font="mdRegular" color="gray500">
              Enter your account details
            </Text>
            <Flex marginTop="48px" flexDirection="column" maxWidth="752px">
              <Flex flexDirection="row" alignItems="center">
                {errors.message?.length && (
                  <Icon icon="alert-circle" color={organicTheme.colors.error700} mr="0.5rem" />
                )}
                <Text as="span" font="smRegular" color="error700">
                  {errors.message}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <FormInput
                  name="phone_number"
                  type="tel"
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  required
                />
              </Flex>
              <Flex flexDirection="column" marginTop="20px">
                <FormInput placeholder="Password" name="password" label="Password" required type="password" />
                <Flex flexDirection="row" justifyContent="flex-end" marginTop="16px">
                  <Link to="#">
                    <Text as="span" font="smMedium" color="primary400">
                      Forgot password
                    </Text>
                  </Link>
                </Flex>
              </Flex>
              <Flex marginTop="48px">
                <Button isLoading={isLoading} display="block" type="submit" isDisabled={isLoading || isSubmitting}>
                  Log in
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </ErrorState>
  );
}
