import React from "react";
import { Image as ShimmerImage, Breathing } from "react-shimmer";

export default function SkeletonImage({
  src = "https://cloud.myfida.com/static/media/fida-logo-small.9f2ac7b7973183e31bbd2af96ffb7644.svg",
  width = "100%",
  height = "auto",
  fallback = <Breathing width={"100%"} height={"auto"} />,
  ...restProps
}) {
  return <ShimmerImage width={width} height={height} {...restProps} src={src} fallback={fallback} />;
}
