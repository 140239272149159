/* eslint-disable no-misleading-character-class */
import { Flex, Text } from "organic";
import React, { useEffect, useState } from "react";
import { marked } from "marked";

export const TAIResponse = ({ text }) => {
  return (
    <>
      <Flex backgroundColor="gray100" borderRadius="6px" px="20px" py="14px">
        <Text font="smRegular" color="gray800" fontWeight="400">
          <Typewriter text={marked.parse(text.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, ""))} />
        </Text>
      </Flex>
      {/* Actions */}
      <Flex></Flex>
    </>
  );
};

export const Typewriter = ({ text, speed = 30, isActive = true }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = isActive ? 0 : text?.length - 1;
    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText(prev => prev + text[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, speed);

    if (!isActive) {
      setDisplayedText(text);
    }

    return () => clearInterval(interval);
  }, [text, speed]);

  return <span dangerouslySetInnerHTML={{ __html: marked.parse(displayedText) }} />;
};
