import React, { useEffect } from "react";
import { StyledLabel, StyledTextArea, StyledTextAreaGroup } from "./styledInput";
import { Text } from "../primary";
import Box from "../layout/box";
import Icon from "../icon/icon";
import { TEXT_AREA_MAX_HEIGHT, TEXT_AREA_MIN_HEIGHT } from "organic/theme/constants";
import colors from "organic/theme/colors";

const TextArea = React.forwardRef(
  (
    {
      hint,
      cols,
      rows,
      description,
      errorText,
      label,
      isInvalid,
      name,
      value,
      onChange,
      isDisabled,
      isRequired,
      placeholder,
      defaultValue,
      leftAddon,
      rightAddon,
      isRounded,
      containerStyles,
      ...restTextAreaProps
    },
    ref
  ) => {
    const innerRef = React.useRef(ref);
    // useEffect(() => {
    //   adjustHeight();
    // }, [value]);

    useEffect(() => {
      adjustHeight();
    }, []);

    const adjustHeight = () => {
      const el = innerRef?.current;
      if (!el) return;

      el.style.height = "auto"; // Reset to auto to recalculate height
      el.style.height = `${Math.max(TEXT_AREA_MIN_HEIGHT, Math.min(el.scrollHeight, TEXT_AREA_MAX_HEIGHT))}px`;
    };

    return (
      <Box position="relative">
        <Box mb={2}>
          <StyledLabel fontSize={14} pb={2}>
            {label}
            {isRequired === true ? (
              <Text as="span" color="primary600">
                *
              </Text>
            ) : (
              ""
            )}
          </StyledLabel>
        </Box>
        <StyledTextAreaGroup
          isRounded={isRounded}
          minHeight={TEXT_AREA_MIN_HEIGHT}
          maxHeight={TEXT_AREA_MAX_HEIGHT}
          {...containerStyles}
        >
          <>{leftAddon || null}</>
          <StyledTextArea
            {...restTextAreaProps}
            ref={innerRef}
            name={name}
            value={value}
            onChange={onChange}
            onInput={adjustHeight}
            disabled={isDisabled}
            required={isRequired}
            placeholder={placeholder}
            defaultValue={defaultValue}
            rows={rows}
            cols={cols}
          />
          {description ? <Icon icon="help-circle" /> : null}
          {isInvalid ? <Icon icon="alert-circle" size={16} color={colors.error500} /> : null}
          <>{rightAddon || null}</>
        </StyledTextAreaGroup>

        <Text>{hint}</Text>
        <Text as="span" font="smRegular" color="error700">
          {errorText ? errorText : ""}
        </Text>
      </Box>
    );
  }
);

TextArea.displayName = "TextArea";

export { TextArea };
