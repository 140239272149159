export function toPixels(value, scale = 16) {
  if (typeof value === "number") {
    return { pxString: `${value}px`, pxValue: value }; // Direct number input
  }

  if (typeof value !== "string") {
    throw new Error("Value must be a number or a string with a unit (e.g., '1.5rem')");
  }

  const unitRegex = /^([\d.]+)([a-zA-Z%]*)$/;
  const match = value.match(unitRegex);

  if (!match) {
    throw new Error("Invalid format. Use a valid unit (e.g., '10rem', '50%').");
  }

  let [__, num, unit] = match;
  num = parseFloat(num);

  if (isNaN(num)) {
    throw new Error("Invalid number in the value.");
  }

  // If there's no unit, assume it's already in px
  if (!unit) {
    return { pxString: `${num}px`, pxValue: num };
  }

  let pxValue;

  switch (unit) {
    case "px":
      pxValue = num;
      break;
    case "rem":
    case "em":
      pxValue = num * scale;
      break;
    case "vw":
      pxValue = (num / 100) * window.innerWidth;
      break;
    case "vh":
      pxValue = (num / 100) * window.innerHeight;
      break;
    case "%":
      throw new Error("Percentage conversion requires a context (e.g., element width).");
    case "cm":
      pxValue = num * 37.7953;
      break;
    case "mm":
      pxValue = num * 3.77953;
      break;
    case "in":
      pxValue = num * 96;
      break;
    case "pt":
      pxValue = num * (96 / 72);
      break;
    case "pc":
      pxValue = num * (96 / 6);
      break;
    default:
      throw new Error(`Unsupported unit: ${unit}`);
  }

  return {
    pxString: `${pxValue}px`,
    pxValue,
  };
}
