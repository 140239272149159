import { lazy } from "react";
import { SkeletonImage } from "./components/common/Image";

const components = {
  badge: lazy(() => "./components/common/ShimmerBadge"),
  button: lazy(() => "./components/common/ShimmerButton"),
  categoryItem: lazy(() => "./components/module/ShimmerCategoryItem"),
  categoryList: lazy(() => "./components/module/ShimmerCategoryList"),
  circularImage: lazy(() => "./components/common/ShimmerCircularImage"),
  featuredGallery: lazy(() => "./components/modules/ShimmerFeaturedGallery"),
  image: SkeletonImage,
  post: lazy(() => "./components/modules/ShimmerPostItem"),
  postDetails: lazy(() => "./components/modules/ShimmerPostDetails"),
  postList: lazy(() => "./components/modules/ShimmerPostList"),
  sectionHeader: lazy(() => "./components/modules/ShimmerSectionHeader"),
  simpleGallery: lazy(() => "./components/modules/ShimmerSimpleGallery"),
  socialPost: lazy(() => "./components/modules/ShimmerSocialPost"),
  table: lazy(() => "./components/common/ShimmerTable"),
  text: lazy(() => "./components/common/ShimmerText"),
  thumbnail: lazy(() => "./components/common/ShimmerThumbnail"),
  title: lazy(() => "./components/common/ShimmerTitle"),
};

export default components;
