import { sessionStorageKeys } from "constants/storageConstants";
import jwtDecode from "jwt-decode";
import { AOSClientError } from "./AOSErrorService";

export async function getAuthSession() {
  try {
    const token = sessionStorage.getItem(sessionStorageKeys.TOKEN);
    if (!token) {
      return { token: "", decodedToken: {}, isValid: false };
    }
    const payload = jwtDecode(token);
    const now = Date.now() / 1000; // Convert to seconds
    const isValid = payload.exp < now;
    return await Promise.resolve({ token, decodedToken: payload, isValid });
  } catch (sessionError) {
    console.log({ sessionError });
    const bug = new AOSClientError("SessionError: not a valid accessToken");
    bug.report(sessionError);
    return { token: "", decodedToken: {}, isValid: false };
  }
}

export function authChecker() {
  return sessionStorage.getItem(sessionStorageKeys.TOKEN) ? true : false;
}

export function setAuthCredentials({ token, refresh, organization, scope }) {
  console.log("called");
  sessionStorage.setItem(sessionStorageKeys.ORG, organization);
  sessionStorage.setItem(sessionStorageKeys.TOKEN, token);
  sessionStorage.setItem(sessionStorageKeys.REFRESH, refresh);
  sessionStorage.setItem(sessionStorageKeys.SCOPE, scope);
}
