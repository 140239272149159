import { useDashboardData } from "hooks";
import { Flex, Text } from "organic";
import React, { useEffect, useRef } from "react";
import { TAIPrompt } from "./TAIPrompt";
import { TAIResponse } from "./TAIResponse";
import TAILoading from "./TAILoading";
import { useTAIDeepseek } from "hooks/app";

// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// `;
// const RotatingIcon = styled.img`
//   animation: ${rotate} 2s linear infinite;
//   width: 50px;
//   height: 50px;
// `;

export const TAIConversation = () => {
  const containerRef = useRef();
  const { dashboardState } = useDashboardData();

  const { isLoading } = useTAIDeepseek();

  const currentConversation = React.useMemo(() => {
    return dashboardState.currentTAIConversation;
  }, [dashboardState.currentTAIConversation]);

  useEffect(() => {
    const el = containerRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight; // Scroll to bottom
    }
  }, [currentConversation]);

  return (
    <Flex flexDirection="column" width="100%" ref={containerRef}>
      {currentConversation?.map?.((item, index) => (
        <Flex flexDirection="column" gap="28px" key={index.toString()} width="100%">
          <TAIPrompt text={item.prompt} />
          <TAIResponse isActive={true} text={JSON.parse(JSON.stringify(item.response))} />
        </Flex>
      ))}
      {dashboardState.isTAIGenerating || isLoading ? (
        <Flex flexDirection="column" width="100%" textAlign="center" justifyContent="center" alignItems="center">
          <TAILoading />
          <Text>Generating...</Text>
        </Flex>
      ) : null}
    </Flex>
  );
};
