import { dashboardLayoutConstants, getDashboardState } from "features/v1/dashboard";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

function useDashboardData() {
  const reduxDispatch = useDispatch();
  let dashboardState = useSelector(getDashboardState);

  const onOpenAI = () => {
    return reduxDispatch({ type: dashboardLayoutConstants.actions.SHOW_TAI });
  };
  const onCloseAI = () => {
    return reduxDispatch({ type: dashboardLayoutConstants.actions.HIDE_TAI });
  };

  const toggleTAIActive = () => {
    reduxDispatch({ type: dashboardLayoutConstants.actions.TOGGLE_TAI_ACTIVE });
  };

  const setTAIPrompt = value => {
    reduxDispatch({ type: dashboardLayoutConstants.actions.SET_AI_PROMPT, payload: value });
  };
  const setTAIView = value => {
    reduxDispatch({ type: dashboardLayoutConstants.actions.SET_AI_VIEW, payload: value });
  };

  const setCurrentTAIConversation = value => {
    reduxDispatch({ type: dashboardLayoutConstants.actions.SET_CURRENT_CONVERSATION, payload: value });
  };

  return useMemo(
    () => ({
      dashboardState,
      onOpenTAI: onOpenAI,
      onCloseTAI: onCloseAI,
      toggleTAIActive,
      setTAIPrompt,
      setTAIView,
      setCurrentTAIConversation,
    }),
    [dashboardState]
  );
}

export { useDashboardData };
