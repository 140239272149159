import { FormGridHeader } from "components/layout";
import { ProjectSelect, selectConstants } from "components/select";
import { getRegionOptions } from "features/v1/regions";
import { Form, Formik } from "formik";
import { Button, CsvUpload, Flex, TextBox } from "organic";
import React from "react";
import { useSelector } from "react-redux";
import { selectLabelFromValue } from "utils/v1/selectUtils";

const BulkUploadFarmers = ({ selected }) => {
  let regionOptions = useSelector(getRegionOptions);
  return (
    <Flex flexDirection="column" gap="2rem">
      <FormGridHeader>Agent details</FormGridHeader>
      <Flex flexDirection="column" gap="1rem">
        <TextBox label={"Name"} value={`${selected.first_name} ${selected.last_name}`} />
        <TextBox label={"Region"} value={selectLabelFromValue(regionOptions, selected?.region)} />
        <TextBox label={"Phone Number"} value={selected.phone_number} />
      </Flex>
      <FormGridHeader>Upload details</FormGridHeader>
      <Formik>
        {() => (
          <Form>
            <Flex flexDirection="column" gap="1rem">
              <CsvUpload cols={[]} width={"400px"} />
              <ProjectSelect ignoreGlobalState colorScheme={selectConstants.colorSchemes.DEFAULT} />
              <Button display="block" isDisabled>
                Upload
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default BulkUploadFarmers;
