import React from "react";
import { Icon } from "organic";

import StyledButton, {
  BaseButton,
  DestructiveButton,
  SocialButton,

  //  StyledAppStoreButton
} from "./styledButton";
import { isFunction } from "utils";
// import { object } from "prop-types";
// import { Box } from "./layout";
// import { PulseSpinner } from "spinners"; // placeholder

const buttonConstants = {
  variants: {
    BASE: "base",
    DESTRUCTIVE: "destructive",
    SOCIAL: "social",
    APP_STORE: "appStore",
    UNSTYLED: "unstyled",
  },
  colorSchemes: {
    PRIMARY: "primary",
    SECONDARY_GRAY: "secondaryGray",
    SECONDARY_COLOR: "secondaryColor",
    TERTIARY_GRAY: "tertiaryGray",
    TERTIARY_COLOR: "tertiaryColor",
    LINK_GRAY: "linkGray",
    LINK_COLOR: "linkColor",
  },
  sizes: {
    SM: "sm",
    MD: "md",
    LG: "lg",
    XL: "xl",
    XXL: "xxl",
  },
  appColors: {
    BLACK: "black",
    WHITE: "white",
  },
  display: {
    BLOCK: "block",
    FLEX: "flex",
    INLINE_FLEX: "inline-flex",
    INLINE_BLOCK: "inline-block",
  },
};

const buttonVariants = {
  base: BaseButton,
  destructive: DestructiveButton,
  social: SocialButton,
  unstyled: StyledButton,

  //   appStore: AppStoreButton,
};

const Button = React.forwardRef(
  (
    {
      // black,
      onClick,
      isActive,
      variant,
      //   isLoading,
      leftIcon,
      rightIcon,
      isDisabled,
      iconOnly,
      children,
      colorScheme,
      display,
      text,
      // appColor,
      //   spinner,
      size,
      style,
      rightAddon,
      iconSize,
      ...restProps
    },
    forwardedRef
  ) => {
    const handleClick = e => {
      if (!isDisabled && isFunction(onClick)) {
        onClick(e);
      }
    };

    // const displayTypeDefaults = display => {
    //   object.values(buttonConstants.display).includes(display) ? display : "flex";
    // };

    const ButtonVariant = buttonVariants[`${variant}`];

    //   const Spinner = spinner;

    return (
      <ButtonVariant
        {...restProps}
        onClick={handleClick}
        disabled={isDisabled}
        active={isActive}
        sm={size === buttonConstants.sizes.SM}
        md={size === buttonConstants.sizes.MD}
        lg={size === buttonConstants.sizes.LG}
        xl={size === buttonConstants.sizes.XL}
        xxl={size === buttonConstants.sizes.XXL}
        primary={colorScheme === buttonConstants.colorSchemes.PRIMARY}
        secondaryGray={colorScheme === buttonConstants.colorSchemes.SECONDARY_GRAY}
        secondaryColor={colorScheme === buttonConstants.colorSchemes.SECONDARY_COLOR}
        tertiaryGray={colorScheme === buttonConstants.colorSchemes.TERTIARY_GRAY}
        tertiaryColor={colorScheme === buttonConstants.colorSchemes.TERTIARY_COLOR}
        linkGray={colorScheme === buttonConstants.colorSchemes.LINK_GRAY}
        linkColor={colorScheme === buttonConstants.colorSchemes.LINK_COLOR}
        base={buttonConstants.variants.BASE}
        destructive={buttonConstants.variants.DESTRUCTIVE}
        social={buttonConstants.variants.SOCIAL}
        display={display}
        appStore={buttonConstants.variants.APP_STORE}
        black={buttonConstants.appColors.BLACK}
        white={buttonConstants.appColors.WHITE}
        block={display === buttonConstants.display.BLOCK}
        flex={display === buttonConstants.display.FLEX}
        inlineBlock={display === buttonConstants.display.INLINE_BLOCK}
        inlineFlex={display === buttonConstants.display.INLINE_FLEX}
        inline={display === buttonConstants.display.INLINE}
        style={variant === buttonConstants.variants.UNSTYLED ? style : {}}
        ref={forwardedRef}
      >
        {/* {black ? {appColor="black":"white"}} */}
        {leftIcon && <Icon mr="14px" icon={leftIcon} />}
        {/* {isLoading && <Spinner />} */}
        {!children && text ? text : null}
        {rightIcon && <Icon ml="14px" icon={rightIcon} />}
        {(rightAddon && rightAddon) || null}
        {!iconOnly ? children : <Icon icon={iconOnly} size={iconSize} />}
      </ButtonVariant>
    );
  }
);

Button.displayName = "Button";
Button.defaultProps = {
  //   spinner: PulseSpinner,
  size: "md",
  variant: "base",
  colorScheme: "primary",
  isActive: false,
  isDisabled: false,
  display: "flex",
};
export { Button, buttonConstants };
