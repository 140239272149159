import { legacyAuthChecker } from "../../services/v1/authService";
import farmers from "api/farmers";
import { toast } from "react-toastify";
import { specialCharRemover } from "utils/v1/removeSpecialChars";
import generateXYData from "utils/v1/generateXYData";
// import Axios from "axios";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
import { getSubmissionConfigOptions } from "utils/v1/apiUtils";
import { handleActionError } from "services/shared/AOSErrorService";

// actions
const GET_FARM_MAPPING_INFO = "GET_FARM_MAPPING_INFO";
const GET_ALL_FILTER_FARM_MAPPING = "GET_ALL_FILTER_FARM_MAPPING";
const GET_ALL_FILTER_FARM_MAPPING_OVERVIEW = "GET_ALL_FILTER_FARM_MAPPING_OVERVIEW";
const LOAD_FARM_MAPPING_DATA = "LOAD_FARM_MAPPING_DATA";
const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
const RESET_PAGINATION_VALUES_FARM_FARM_MAPPING = "RESET_PAGINATION_VALUES_FARM_FARM_MAPPING";
const FILTER_FARM_MAPPING_DATA = "FILTER_FARM_MAPPING_DATA";
const SEARCH_TERM = "SEARCH_TERM";
const HANDLE_DATE_FILTER = "HANDLE_DATE_FILTER";
const LOAD_SEARCH = "LOAD_SEARCH";
// const CREATE_FIELD = "CREATE_FIELD";
// const LOAD_CREATE_FIELD = "LOAD_CREATE_FIELD";

// action creators

export const getAllFarmMappingsWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_FARM_MAPPING_DATA, payload: true });
    //console.log("params: ",params)
    const resData = await farmers.get("getAllMappingsWithParams", { params });

    const _farmMappingOptions =
      resData?.data?.data?.map(mapping => ({
        label: `${mapping?.farmer?.first_name || "no first name"} ${mapping?.farmer?.last_name || "no last name"} ${
          mapping?.ta_number
        }`,
        value: mapping,
      })) || [];

    dispatch({
      type: GET_ALL_FILTER_FARM_MAPPING,
      payload: resData?.data?.data || [],
      pages: resData.data.pages,
      total: resData.data.total,
      farmMappingMeta: resData?.data?.meta || {},
      farmMappingPageCount: resData.data?.meta?.pageCount || 0,
      farmMappingOptions: _farmMappingOptions,
    });

    dispatch({ type: LOAD_FARM_MAPPING_DATA, payload: false });
  } catch (getAllFarmMappingsWithParamsError) {
    dispatch({ type: LOAD_FARM_MAPPING_DATA, payload: false });
    handleActionError(getAllFarmMappingsWithParamsError);
  }
};
export const searchAllFarmMappingsWithParams =
  ({ params, signal }) =>
  async dispatch => {
    try {
      legacyAuthChecker();
      dispatch({ type: LOAD_SEARCH, payload: true });
      //console.log("params: ",params)
      const resData = await farmers.get("farmMapper/search", { params, signal });

      dispatch({
        type: GET_ALL_FILTER_FARM_MAPPING,
        payload: resData?.data?.data || [],
        pages: resData.data.pages,
        total: resData.data.total,
        farmMappingMeta: resData?.data?.meta || {},
        farmMappingPageCount: resData.data?.meta?.pageCount || 0,
      });

      dispatch({ type: LOAD_SEARCH, payload: false });
    } catch (searchAllFarmMappingsWithParamsError) {
      dispatch({ type: LOAD_SEARCH, payload: false });
      handleActionError(searchAllFarmMappingsWithParamsError);
    }
  };

export const getAllFarmMappingsWithParamsOverview = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_FARM_MAPPING_DATA, payload: true });
    //console.log("params: ",params)
    const resData = await farmers.get("getAllMappingsWithParamsOverview", { params });

    dispatch({
      type: GET_ALL_FILTER_FARM_MAPPING_OVERVIEW,
      payload: resData?.data?.data || {},
      //pages: resData.data.pages,
      //total: resData.data.total,
    });

    dispatch({ type: LOAD_FARM_MAPPING_DATA, payload: false });
  } catch (getAllFarmMappingsWithParamsOverviewError) {
    dispatch({ type: LOAD_FARM_MAPPING_DATA, payload: false });
    handleActionError(getAllFarmMappingsWithParamsOverviewError);
  }
};

export const initiateOneFarmMapper = (values, config) => () => {
  // const hasFormOption = config?.formOptions;
  // const hasModal = config?.modal;
  const params = config?.params || {};

  const sanitizedValues = sanitizeMongoDoc(values);
  farmers
    .post("farmMapper/initiateOne", sanitizedValues, { params })
    .then(__response => {
      // TODO: implement erroh handling
      console.log("an error occured");
    })
    .catch(err => console.log(err));
};

export const filterFarmerMappingData =
  (values, { setSubmitting }) =>
  dispatch => {
    if (Object.keys(values).length < 1) {
      toast.error("Search box must not be empty. Please type a search term");
    }

    dispatch({
      type: FILTER_FARM_MAPPING_DATA,
      payload: values.term,
    });
    setSubmitting(false);
  };

export const handleSearchTerm = term => dispatch => {
  const searchTerm = specialCharRemover(term);
  dispatch({
    type: SEARCH_TERM,
    payload: searchTerm,
  });
};

export const getAllFarmMappingData = () => async dispatch => {
  try {
    legacyAuthChecker();
    const farmMappingData = await farmers.get("/farmMapper/all");

    dispatch({
      type: GET_FARM_MAPPING_INFO,
      payload: farmMappingData.data.data,
    });
  } catch (getAllFarmMappingDataError) {
    handleActionError(getAllFarmMappingDataError);
  }
};

export const createFarmMappingInfo =
  (values, config = {}) =>
  () => {
    const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);
    let params = {
      ta_number: config.selected.ta_number,
      groupId: config.selected.group_id,
    };
    return farmers
      .patch("farmMapper/initiateOne", values, { params })
      .then(response => {
        if (response.status === 200) {
          toast.success("Field Created successfully!");
          if (hasModal) config.modal.hideModal();
        }
      })
      .catch(err => {
        // TODO: make this standard
        handleActionError(err, "Crearte Field Failed: ");
        if (hasFormOptions) config.formOptions.setSubmitting(false);
      });
  };

export const updateFarmMappingInfo = (values, formikOptions, handleModalToggle) => async dispatch => {
  let params = {
    id: values._id,
  };
  try {
    let sanitizedMappingValues = sanitizeMongoDoc(values);
    await farmers.patch("/updateFarmMapperInfo", sanitizedMappingValues, { params });

    toast.success("Farm Mapping Details updated successfully!");

    formikOptions.setSubmitting(false);
    handleModalToggle();
    return dispatch(getAllFarmMappingsWithParams());
  } catch (error) {
    formikOptions.setSubmitting(false);
    handleActionError(error);
  }
};
// FIXME: investigate this might be a misnaming or should be moved to features/farmers
export const editFarmerDetails =
  (values, { setSubmitting, setErrors }, handleModalToggle, farmerId) =>
  () => {
    let sanitizedFarmValues = farmEditSanitize(values);
    let params = {
      id: farmerId,
    };
    farmers
      .put("/farmer/update", sanitizedFarmValues, { params })
      .then(response => {
        if (response.status === 200) {
          toast.success("Farmer Details updated successfully!");
          setSubmitting(false);
          handleModalToggle();
        }
        // else if (response.status === 401) {
        //   toast.error('You are not authorized to do that!');
        //   setSubmitting(false);
        // }
      })
      .catch(err => {
        setErrors({
          message: err.response.data || err.message || err.response.data.message || err.response.data.error,
        });
      })
      .then(() => setSubmitting(false));

    return {
      setSubmitting,
      setErrors,
    };
  };

export const resetPaginationValues = () => dispatch => {
  dispatch({
    type: RESET_PAGINATION_VALUES_FARM_FARM_MAPPING,
  });
};
export const changePageSize = perPage => dispatch => {
  dispatch({
    type: CHANGE_PAGE_SIZE,
    payload: perPage,
  });
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;

  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};
export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  dispatch({
    type: HANDLE_PAGE_CHANGE,
    payload: pageNumber,
  });
  dispatch(handleOffset(pageNumber, pageSize));
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY,
    payload: arrayOfInfo,
  });
};

const farmEditSanitize = values => {
  //hotfix for empty bvn string bug
  if (values.bvn === "") {
    values.bvn = undefined;
  }
  //immutable values, not needed for update endpoint
  if (values.group_id && values.ta_number) {
    delete values.group_id;
    delete values.ta_number;
  }
  return values;
};

//reducer
const initialState = {
  farmMappingData: [],
  farmMappingMeta: {},
  farmMappingOptions: [],
  farmMappingPageCount: 0,
  loadingSearch: false,
  commitmentFeeTarget: 950000,
  mappingCountChartData: [],
  mappingSizeChartData: [],
  targetFieldSize: 50000, // in Hectares
  totalFieldSize: 0, //in Hectares
  averageFieldSize: 0,
  loading: false,
  pageSize: 10,
  pageNumber: 1,
  offset: 0,
  info: [],
  filteredData: [],
  filteredByDate: [],
  searchTerm: "",
  date: new Date(),
};

function middlewareFilterData(state, action) {
  try {
    // console.log(
    //   "============================= GET_ALL_FILTER_FARM_MAPPING ================"
    // );
    let _data = action.payload;
    let totalFieldSize = 0,
      averageFieldSize = 0,
      ignoredFieldSizeData = [],
      mappingSizeChartData = [],
      mappingCountChartData = [];
    //console.time("imperative for");
    for (var i = 0; i < _data.length; i++) {
      totalFieldSize += parseFloat(_data[i].farm_size); //? _data[i].farm_size : 0
    }
    //console.timeEnd("imperative for");
    totalFieldSize = totalFieldSize.toFixed(2);
    averageFieldSize =
      isNaN(totalFieldSize / _data.length) || totalFieldSize / _data.length == Infinity
        ? 0
        : Math.abs(totalFieldSize / _data.length);
    averageFieldSize = averageFieldSize.toFixed(2);
    ignoredFieldSizeData = mappingSizeChartData = _data.map(mapping => ({
      y: mapping?.farm_size,
      x: mapping?.created_at,
    }));
    mappingCountChartData = generateXYData(_data);
    // console.time("======= chaning reduce ")
    // totalFieldSize = (_data.length >= 1) && _data.reduce((acc, val) => acc + val.farm_size)
    // console.timeEnd("======= chaning reduce ")
    return {
      ...state,
      farmMappingData: _data,
      farmMappingPageCount: action.farmMappingPageCount,
      farmMappingMeta: action.farmMappingMeta,
      totalFieldSize,
      averageFieldSize,
      mappingCountChartData,
      mappingSizeChartData,
      pages: action.pages,
      total: _data.length,
      farmMappingOptions: action.farmMappingOptions,
    };
  } catch (error) {
    console.log({ error });
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FARM_MAPPING_INFO:
      return { ...state, farmMappingData: action.payload };
    case GET_ALL_FILTER_FARM_MAPPING:
      return middlewareFilterData(state, action);
    case GET_ALL_FILTER_FARM_MAPPING_OVERVIEW: {
      let { numberOfFields, totalFieldsMappedInHectare, averageFieldSize } = action.payload;
      return {
        ...state,
        totalFieldSize: totalFieldsMappedInHectare,
        averageFieldSize: averageFieldSize,
        total: numberOfFields,
      };
    }
    case LOAD_FARM_MAPPING_DATA:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_SEARCH:
      return {
        ...state,
        loadingSearch: action.payload,
      };
    case CHANGE_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case HANDLE_PAGE_CHANGE:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY: {
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize);
      return { ...state, info: infoToDisplay };
    }
    case FILTER_FARM_MAPPING_DATA: {
      const searchTerm = new RegExp(action.payload, "i");
      // prettier-ignore
      const filteredData = state
      .farmMappingData
      .filter((farmMappingData) => Object.values(farmMappingData)
        .find(farmMappingData => searchTerm.test(farmMappingData)));

      return { ...state, filteredData };
    }
    case SEARCH_TERM: {
      const term = new RegExp(action.payload, "i");
      // prettier-ignore
      const result = state
      .farmMappingData
      .filter((farmMappingData) => Object.values(farmMappingData)
        .find(farmMappingData => term.test(farmMappingData)));
      return { ...state, searchTerm: action.payload, filteredData: result };
    }
    case HANDLE_DATE_FILTER:
      return {
        ...state,
        date: action.payload.date,
        filteredByDate: action.payload.filteredByDate,
      };
    case RESET_PAGINATION_VALUES_FARM_FARM_MAPPING:
      return { ...state, pageNumber: 1, offset: 0 };
    default:
      return state;
  }
};

// selector functions
export const getFarmMappingData = state => state.legacyFarmMapping.farmMappingData;
export const getFarmMappingMeta = state => state.legacyFarmMapping.farmMappingMeta;
export const getFarmMappingPageCount = state => state.legacyFarmMapping.farmMappingPageCount;
export const getLoadingSearch = state => state.legacyFarmMapping.loadingSearch;
export const getCommitmentFeeTarget = state => state.legacyFarmMapping.commitmentFeeTarget;
export const getMappingCountChartData = state => state.legacyFarmMapping.mappingCountChartData;
export const getMappingSizeChartData = state => state.legacyFarmMapping.mappingSizeChartData;
export const getTargetFieldSize = state => state.legacyFarmMapping.targetFieldSize;
export const getTotalFieldSize = state => state.legacyFarmMapping.totalFieldSize;
export const getAverageFieldSize = state => state.legacyFarmMapping.averageFieldSize;
export const getLoadingFarmMapping = state => state.legacyFarmMapping.loading;
export const getPageSize = state => state.legacyFarmMapping.pageSize;
export const getPageNumber = state => state.legacyFarmMapping.pageNumber;
export const getOffset = state => state.legacyFarmMapping.offset;
export const getInfo = state => state.legacyFarmMapping.info;
export const getFilteredData = state => state.legacyFarmMapping.filteredData;
export const getFilteredByDate = state => state.legacyFarmMapping.filteredByDate;
export const getSearchTerm = state => state.legacyFarmMapping.searchTerm;
export const getDate = state => state.legacyFarmMapping.date;
export const getFarmMappingOptions = state => state.legacyFarmMapping.farmMappingOptions;
