import { Flex, Icon, Text } from "organic";
import React from "react";
import { Box } from "organic";
import { useDisclosure } from "hooks";
import {
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownSeparator,
  DropdownTrigger,
} from "components/dropdown";
import { ProjectFilter } from "components/filter";
import { Modal, ModalContent } from "components/modal";
import CreateAdminForm from "views/forms/createAdminForm";
import EditUserForm from "views/forms/editUserForm";
import AvatarUrl from "utils/ta-utils/avatar-url";
import { ScopedView } from ".";
import { useLogout, useUserData } from "hooks/app";

const HEADER_HEIGHT = "4rem";

const Header = () => {
  const { avatar_url, first_name, last_name, email } = useUserData();
  // profile image should be part of the userdata

  const profileDisclosure = useDisclosure();
  const createAdminDisclosure = useDisclosure();

  const logout = useLogout();

  const handleLogOut = () => {
    logout();
  };

  const handleShowProfile = () => {
    profileDisclosure.onOpen();
  };
  const handleCreateAdmin = () => {
    createAdminDisclosure.onOpen();
  };

  return (
    <Box id="headerContainer">
      <Box
        as="header"
        backgroundColor="white"
        z-index="70"
        position="fixed"
        left="0px"
        right="0px"
        top="0px"
        height={HEADER_HEIGHT}
        borderBottomWidth="2px"
        borderBottomStyle="solid"
        borderBottomColor="primary700"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
      >
        <Flex flexDirection="row" py="1rem" justifyContent="space-between" px="1rem">
          <ProjectFilter />
          <DropdownMenu className="profile-dropdown">
            <DropdownTrigger className="profile-dropdown__trigger">
              {/* {avatar_url ? <Image src={avatar_url} borderRadius="full" size="32px" /> : <FaUserCircle size={32} />} */}
              <AvatarUrl avatarUrl={avatar_url} borderRadius="full" size="32px" />
              <Icon icon="chevron-down" size={12} ml="4px" />
            </DropdownTrigger>
            <DropdownContent alignOffset={-5} align="end" className="profile-dropdown__content">
              <DropdownLabel className="profile-dropdown__label">
                <Text font="smSemibold" color="gray700">
                  {first_name || ""} {last_name || ""}
                </Text>
                <Text font="xsRegular" color="gray500">
                  {email}
                </Text>
              </DropdownLabel>
              <DropdownSeparator className="profile-dropdown__separator" />
              <ScopedView resource={"all"} permissionRequired="write">
                <DropdownItem onSelect={handleCreateAdmin} className="profile-dropdown__item">
                  Create Admin
                </DropdownItem>
              </ScopedView>
              <DropdownItem onSelect={handleShowProfile} className="profile-dropdown__item">
                Profile
              </DropdownItem>
              <DropdownSeparator className="profile-dropdown__separator" />
              <DropdownItem onSelect={handleLogOut} className="profile-dropdown__logout-item">
                Log out
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </Flex>
      </Box>
      <Modal {...profileDisclosure.modalProps}>
        <ModalContent title="Edit your Profile">
          <EditUserForm modal={profileDisclosure.modal} />
        </ModalContent>
      </Modal>
      <Modal {...createAdminDisclosure.modalProps}>
        <ModalContent title="Create Admin User">
          <CreateAdminForm modal={createAdminDisclosure.modal} />
        </ModalContent>
      </Modal>
      {/* <Box height={HEADER_HEIGHT} width="100%" backgroundColor="transparent" border="1px solid green"></Box> */}
    </Box>
  );
};

export { Header, HEADER_HEIGHT };
