import React from "react";
import StyledImage from "./styledImage";
import { Skeleton } from "../skeletons";

export const Image = props => {
  if (props?.withSkeleton) {
    return <Skeleton type="image" {...props} />;
  }

  return <StyledImage {...props} />;
};
