import { toast } from "react-toastify";
import { legacyAuthChecker } from "../../services/v1/authService";
import subscription from "api/subscription";
import farmers from "api/farmers";

// actions

// commitment fees actions
const GET_ALL_FILTERED_COMMITMENT_FEES = "GET_ALL_FILTERED_COMMITMENT_FEES";
const LOAD_COMMITMENT_FEES = "LOAD_COMMITMENT_FEES";
// const RESET_COMMITMENT_PAGINATION_VALUES = "RESET_COMMITMENT_PAGINATION_VALUES";
// const CHANGE_COMMITMENT_PAGE_SIZE = "CHANGE_COMMITMENT_PAGE_SIZE";
// const CHANGE_COMMITMENT_OFFSET_VALUE = "CHANGE_COMMITMENT_OFFSET_VALUE";
// const HANDLE_COMMITMENT_PAGE_CHANGE = "HANDLE_COMMITMENT_PAGE_CHANGE";
// const SET_COMMITMENT_INFO_TO_DISPLAY = "SET_COMMITMENT_INFO_TO_DISPLAY";
const HANDLE_SEARCH_COMMITMENT = "HANDLE_SEARCH_COMMITMENT";
const HANDLE_SEARCH_EQUITY = "HANDLE_SEARCH_EQUITY";

// equity Contribution
const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION";
const GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY = "GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY";
const GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT = "GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT";
const LOAD_SUBSCRIPTION = "LOAD_SUBSCRIPTION";
// const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
// const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
// const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
// const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
// const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
// const LOAD_SEARCH = "LOAD_SEARCH";

// action creators
export const getAllCommitmentFeesWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_COMMITMENT_FEES, payload: true });
    const resData = await farmers.get("getAllMappingsWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_COMMITMENT_FEES,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
      commitmentFeeMeta: resData?.data?.meta || {},
    });

    dispatch({ type: LOAD_COMMITMENT_FEES, payload: false });
  } catch (getAllCommitmentFeesWithParamsError) {
    console.log({ getAllCommitmentFeesWithParamsError });
  }
};
export const searchAllCommitmentFeesWithParams = (params, __cancelToken) => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_COMMITMENT_FEES, payload: true });
    const resData = await farmers.get("getAllMappingsWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_COMMITMENT_FEES,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
      commitmentFeeMeta: resData?.data?.meta || {},
    });

    dispatch({ type: LOAD_COMMITMENT_FEES, payload: false });
  } catch (searchAllCommitmentFeesWithParamsError) {
    console.log({ searchAllCommitmentFeesWithParamsError });
  }
};

export const updateCommitmentFeeWithParams =
  (values, { setSubmitting, setErrors }, feeId, handleModalClose, handleGetCommitmentFees) =>
  __dispatch => {
    let params = {
      id: feeId,
    };
    if (legacyAuthChecker()) {
      farmers
        .put("updateFarmMapperInfo", values, { params })
        .then(res => {
          if (res.status === 200) {
            toast.success("Commitment Fee updated successfully!");
            setSubmitting(false);
            handleGetCommitmentFees();
            handleModalClose();
          }
        })
        .catch(err => {
          setErrors({
            message: err?.response?.data || err?.message || err?.response?.data?.error,
          });
        })
        .then(() => setSubmitting(false));

      return {
        setSubmitting,
        setErrors,
      };
    } else {
      toast.error("you don't have permission to perform this action");
      setSubmitting(false);
      return {
        setSubmitting,
        setErrors,
      };
    }
  };

export const getAllSubActivityWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_SUBSCRIPTION, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("getAllFarmSubActivityWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
  } catch (getAllSubActivityWithParamsError) {
    console.log({ getAllSubActivityWithParamsError });
  }
};

export const getAllSubPaymentWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_SUBSCRIPTION, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("getAllFarmSubPaymentsWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
      subPaymentMeta: resData?.data?.meta || {},
    });

    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
  } catch (getAllSubPaymentWithParamsError) {
    console.log({ getAllSubPaymentWithParamsError });
  }
};

export const updateSubscriptionPayment =
  (values, { setSubmitting }, subId, handleModalClose, getAllSubPayment) =>
  async __dispatch => {
    try {
      let params = {
        id: subId,
        equityORcommitfee: "EQUITY",
      };
      const res = await subscription.put("editFarmSubscription", values, { params });

      if (res.status === 200) {
        setSubmitting(false);
        handleModalClose();
        getAllSubPayment();
        toast.success("Subscription Payment updated successfully!");
      }
      // else if (res.status === 401) {
      //   toast.error('You are not authorized to do that!');
      //   setSubmitting(false);
      // }
    } catch (updateSubscriptionPaymentError) {
      console.log({ updateSubscriptionPaymentError });
      setSubmitting(false);
    }
  };

export const handleSearchCommitment = searchTerm => dispatch => {
  dispatch({ type: HANDLE_SEARCH_COMMITMENT, payload: searchTerm });
};
function middlewareSearchCommitment(state, action) {
  let _dataCopy = state.commitmentFeesData;
  if (action.payload === "") {
    return { ...state, commitmentFeesData: state.commitmentFeesSearchData };
  } else {
    let filteredData = _dataCopy.filter(
      fee =>
        fee?.ta_number?.match(action.payload) ||
        fee?.farmer?.first_name?.match(action.payload) ||
        fee?.farmer?.last_name?.match(action.payload)
    );
    return { ...state, commitmentFeesData: filteredData };
  }
}
export const handleSearchEquity = searchTerm => dispatch => {
  dispatch({ type: HANDLE_SEARCH_EQUITY, payload: searchTerm });
};
function middlewareSearchEquity(state, action) {
  let _dataCopy = state.subscriptionPaymentData;
  if (action.payload === "") {
    return {
      ...state,
      subscriptionPaymentData: state.subscriptionPaymentSearchData,
    };
  } else {
    let filteredData = _dataCopy.filter(
      subscription =>
        subscription?.subscription?.feildName?.match(action.payload) ||
        subscription?.farmerInfo?.first_name?.match(action.payload) ||
        subscription?.farmerInfo?.last_name?.match(action.payload)
    );
    return { ...state, subscriptionPaymentData: filteredData };
  }
}
//reducer
const initialState = {
  subscriptionData: [],
  subscriptionPaymentData: [],
  subPaymentMeta: {},
  subscriptionPaymentSearchData: [],
  subscriptionActivityData: [],
  subscriptionActivityDataCount: 0,
  subPaymentPlanFeePerFieldMapped: 8000,
  subPaymentTarget: 400000000,
  subPaymentTotalAmountPaid: 0,
  loadingCommitment: false,
  allSubscriptionPayments: [],
  subscriptionPaymentsCount: 0,
  commitmentFeesData: [],
  commitementFeeMeta: {},
  commitmentFeesSearchData: [],
  commitmentFeePerFieldMapped: 500,
  commitmentFeesTotalAmountPaid: 0,
  equityTarget: 5500000,
  loadingSubscription: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FILTERED_COMMITMENT_FEES:
      return middlewareCommitmentFilterData(state, action);
    case LOAD_COMMITMENT_FEES:
      return {
        ...state,
        loadingCommitment: action.payload,
      };

    case GET_ALL_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.payload,
        pages: action.pages,
        total: action.total,
      };
    case GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY:
      return {
        ...state,
        subscriptionData: action.payload,
        subscriptionActivityDataCount: action.payload.length,
        pages: action.pages,
        total: action.total,
      };
    case GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT:
      return middlewareEquityFilterData(state, action);
    case HANDLE_SEARCH_COMMITMENT:
      return middlewareSearchCommitment(state, action);
    case HANDLE_SEARCH_EQUITY:
      return middlewareSearchEquity(state, action);
    case LOAD_SUBSCRIPTION:
      return {
        ...state,
        loadingSubscription: action.payload,
      };
    default:
      return state;
  }
};

// middlewares
function middlewareEquityFilterData(state, action) {
  try {
    let _data = action.payload;
    let totalAmountPaid = 0;
    for (var i = 0; i < _data.length; i++) {
      totalAmountPaid += parseFloat(_data[i].subscription.farmer_paid); //? _data[i].farm_size : 0
    }
    return {
      ...state,
      subscriptionPaymentData: _data,
      subPaymentMeta: action.subPaymentMeta,
      subscriptionPaymentSearchData: _data,
      subPaymentTotalAmountPaid: totalAmountPaid,
      pages: action.pages,
      total: action.total,
    };
  } catch (error) {
    console.log({ error });
  }
}
function middlewareCommitmentFilterData(state, action) {
  try {
    //   console.log(
    //     "============================= GET_ALL_FILTERED_AGENTS ================"
    //   );
    let _data = action.payload;
    let commitmentFeesTotalAmountPaid = 0;
    commitmentFeesTotalAmountPaid = _data.length >= 1 ? Math.ceil(state.commitmentFeePerFieldMapped * _data.length) : 0;
    //for(var i = 0; i < _data.length)
    return {
      ...state,
      commitmentFeesData: _data,
      commitmentFeesSearchData: _data,
      commitmentFeeMeta: action.commitmentFeeMeta,
      commitmentFeesTotalAmountPaid,
      pages: action.pages,
      total: action.total,
    };
  } catch (error) {
    console.log({ error });
  }
}
