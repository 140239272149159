import { apiConstants } from "constants/apiConstants";
import { axiosService } from "services/fetchService";
import { getApiUrl } from "utils";

export class MulitTenant_API {
  static async getAllOrganizations() {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.organization, path: "/getAll" });
    return await axiosService({ method: "GET", url });
  }
}
