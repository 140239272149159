import { legacyAuthChecker } from "../../services/v1/authService";
import farmers from "api/farmers";
import { toast } from "react-toastify";
import { specialCharRemover } from "utils/v1/removeSpecialChars";
import generateXYData from "utils/v1/generateXYData";
import _ from "lodash";
// import axios from "axios";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
import { getSubmissionConfigOptions } from "utils/v1/apiUtils";
import { handleActionError } from "services/shared/AOSErrorService";

// types
const GET_ALL_FARMERS = "GET_ALL_FARMERS";
const LOAD_FARMERS_DATA = "LOAD_FARMERS_DATA";
const GET_ALL_FILTER_FARMERS = "GET_ALL_FILTER_FARMERS";
const GET_PAGINATED_FILTER_FARMERS = "GET_PAGINATED_FILTER_FARMERS";
const GET_ALL_FILTER_FARMERS_MODEL_COUNT = "GET_ALL_FILTER_FARMERS_MODEL_COUNT";
const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
const RESET_PAGINATION_VALUES_FARMERS = "RESET_PAGINATION_VALUES_FARMERS";
const FILTER_FARMERS_DATA = "FILTER_FARMERS_DATA";
const SEARCH_TERM = "SEARCH_TERM";
const HANDLE_DATE_FILTER = "HANDLE_DATE_FILTER";
const GET_FARMER_OVERVIEW = "GET_FARMER_OVERVIEW";
const LOAD_SEARCH = "LOAD_SEARCH";

/**
 *
 *
 * @param {Object} FilterParams - date: (startdate, endDate), season: <String>,
 * region: <String>, zone: <String>
 * @return
 **/

// actions
export const getAllFarmersWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();

    dispatch({ type: LOAD_FARMERS_DATA, payload: true });
    const farmerData = await farmers.get("getAllFarmersWithParams", { params });

    dispatch({
      type: GET_ALL_FILTER_FARMERS,
      payload: farmerData.data.data,
      // pages: farmerData.data.pages,
      // total: farmerData.data.total,
      farmersPageCount: farmerData?.data?.meta?.pageCount || 0,
      totalFarmersCount: farmerData?.data?.meta?.totalDocCount || 0,
      farmersMeta: farmerData?.data?.meta || {},
    });

    dispatch({ type: LOAD_FARMERS_DATA, payload: false });
  } catch (getAllFarmersWithParamsError) {
    handleActionError(getAllFarmersWithParamsError);
  }
};
export const searchAllFarmersWithParams =
  (params, { abortController }) =>
  async dispatch => {
    try {
      legacyAuthChecker();
      dispatch({ type: LOAD_SEARCH, payload: true });
      const farmerSearchData = await farmers.get("search", { params, abortController });

      dispatch({
        type: GET_ALL_FILTER_FARMERS,
        payload: farmerSearchData.data.data,
        farmersPageCount: farmerSearchData?.data?.meta?.pageCount || 0,
        totalFarmersCount: farmerSearchData?.data?.meta?.totalDocCount || 0,
        farmersMeta: farmerSearchData?.data?.meta || {},
      });

      dispatch({ type: LOAD_SEARCH, payload: false });
    } catch (searchAllFarmersWithParamsError) {
      dispatch({ type: LOAD_SEARCH, payload: false });
      handleActionError(searchAllFarmersWithParamsError);
    }
  };

export const getAllFarmersWithParamsOverview = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_FARMERS_DATA, payload: true });
    const farmerData = await farmers.get("getAllFarmersWithParamsOverview", { params });

    dispatch({
      type: GET_ALL_FILTER_FARMERS_MODEL_COUNT,
      payload: farmerData.data.data,
    });

    dispatch({ type: LOAD_FARMERS_DATA, payload: false });
  } catch (searchAllFarmersWithParamsError) {
    dispatch({ type: LOAD_FARMERS_DATA, payload: false });
    handleActionError(searchAllFarmersWithParamsError);
  }
};

export const getAllFarmersWithParams3 = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_FARMERS_DATA, payload: true });
    const farmerData = await farmers.get("getAllFarmersWithParams3", { params });

    dispatch({
      type: GET_ALL_FILTER_FARMERS_MODEL_COUNT,
      payload: farmerData.data.data,
    });

    dispatch({ type: LOAD_FARMERS_DATA, payload: false });
  } catch (getAllFarmersWithParams3Error) {
    dispatch({ type: LOAD_FARMERS_DATA, payload: false });
    handleActionError(getAllFarmersWithParams3Error);
  }
};
export const getAllPaginatedFarmersWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();

    dispatch({ type: LOAD_FARMERS_DATA, payload: true });
    const farmerData = await farmers.get("getAllFarmersWithParams", { params });

    dispatch({
      type: GET_PAGINATED_FILTER_FARMERS,
      payload: farmerData.data.data,
      pages: farmerData.data.pages,
      total: farmerData.data.total,
    });

    dispatch({ type: LOAD_FARMERS_DATA, payload: false });
  } catch (getAllPaginatedFarmersWithParamsError) {
    dispatch({ type: LOAD_FARMERS_DATA, payload: false });
    handleActionError(getAllPaginatedFarmersWithParamsError);
  }
};

export const _handleFarmerDateRangeFilter =
  ({ __date, __season, __region, __zone }) =>
  async __dispatch => {};

//move later

export const _handleAgentDateRangeFilter =
  ({ __date, __season, __region, __zone }) =>
  async () => {};

export const handleFilterByDate = (date, location, season) => async dispatch => {
  if (location !== "farmers") return;
  let params = {
    date,
    season,
  };
  try {
    let queriedFarmers = await farmers.get("/farmerDate/count", { params });

    if (queriedFarmers.status === 404) {
      toast.error("No farmers onboarded on this date");
      return;
    }
    toast.success("Success");
    dispatch({
      type: HANDLE_DATE_FILTER,
      payload: {
        date,
        filteredByDate: queriedFarmers.data.data.farmers,
        filteredPages: queriedFarmers.data.data.pages,
        filteredTotal: queriedFarmers.data.data.foundFarmersCount,
      },
    });
  } catch (error) {
    if (error.response.status === 404) {
      toast.error("No farmers onboarded on this date");
      return;
    }
    toast.error("Something went wrong fetching farmers");
    return;
  }
};

export const filterFarmersData =
  (values, { setSubmitting }) =>
  dispatch => {
    if (Object.keys(values).length < 1) {
      toast.error("Search box must not be empty. Please type a search term");
    }

    dispatch({
      type: FILTER_FARMERS_DATA,
      payload: values.term,
    });
    setSubmitting(false);
  };

export const handleSearchTerm = term => dispatch => {
  const searchTerm = specialCharRemover(term);
  dispatch({
    type: SEARCH_TERM,
    payload: searchTerm,
  });
};

export const getPaginatedFarmersBySeason =
  (page_number, per_page, season, { ...options }) =>
  dispatch => {
    legacyAuthChecker();
    dispatch({
      type: LOAD_FARMERS_DATA,
      payload: true,
    });
    const params = {
      page_number,
      per_page,
      season,
    };
    if (options && options.showAllSeasons) {
      farmers
        .get("/", { page_number, per_page })
        .then(farmerData => {
          dispatch({
            type: GET_ALL_FARMERS,
            payload: farmerData.data.data,
            pages: farmerData.data.pages,
            total: farmerData.data.total,
          });

          dispatch({
            type: LOAD_FARMERS_DATA,
            payload: false,
          });
        })
        .catch(__err => {
          dispatch({
            type: LOAD_FARMERS_DATA,
            payload: false,
          });
          // if (err.response.status === 401) {
          //   return toast.error(err.response.data.message);
          // }
          toast.error("Something went wrong fetching Farmers, try refreshing the page");
        });
    } else {
      farmers
        .get("/currentSeason", { params })
        .then(farmerData => {
          dispatch({
            type: GET_ALL_FARMERS,
            payload: farmerData.data.data,
            pages: farmerData.data.pages,
            total: farmerData.data.total,
          });

          dispatch({ type: LOAD_FARMERS_DATA, payload: false });
        })
        .catch(__err => {
          // if (err.response.status === 401) {
          //   return toast.error(err.response.data.message);
          // }
          toast.error("Something went wrong fetching Farmers, try refreshing the page");
          dispatch({ type: LOAD_FARMERS_DATA, payload: false });
        });
    }
  };

export const handlePaginatedPageChange =
  (page_number, per_page, season, { ...__options }) =>
  dispatch => {
    legacyAuthChecker();
    const params = {
      page_number,
      per_page,
      season,
    };

    farmers
      .get("/currentSeason", { params })
      .then(farmerData => {
        dispatch({
          type: GET_ALL_FARMERS,
          payload: farmerData.data.data,
          pages: farmerData.data.pages,
          // total: farmerData.data.total,
        });
      })
      .catch(__err => {
        toast.error("Something went wrong fetching Farmers, try refreshing the page");
      });
  };

export const getAllFarmers = () => dispatch => {
  legacyAuthChecker();
  dispatch({ type: LOAD_FARMERS_DATA, payload: true });
  return farmers
    .get()
    .then(farmerData => {
      dispatch({
        type: GET_ALL_FARMERS,
        payload: farmerData.data.data,
      });

      dispatch({ type: LOAD_FARMERS_DATA, payload: false });
    })
    .catch(__err => {
      toast.error("Something went wrong fetching Farmers, try refreshing the page");
      dispatch({ type: LOAD_FARMERS_DATA, payload: false });
    });
};

export const editFarmerDetails =
  (values, config = {}) =>
  dispatch => {
    dispatch({ type: LOAD_FARMERS_DATA, payload: true });
    const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);
    let params = {
      id: config.selected._id,
    };
    let sanitizedFarmValues = sanitizeMongoDoc(values, [
      "avatar_url",
      "old_ta_number",
      "ta_number",
      "country",
      "coords",
      "lead_farmer_id",
      "lead_ta_number",
      "assesmentFinalScore",
      "group_id",
      "level",
      "agent_id",
      "clearedForInputDistribution",
      "expectedGroupSubscriptionTotal",
      "commitmentFee",
      "agent",
      "season",
      "commitmentFeeList",
      "crop",
      "syncTocloud",
    ]);
    farmers
      .put("/farmer/update", sanitizedFarmValues, { params })
      .then(response => {
        if (response.status === 200) {
          toast.success("Farmer Details updated successfully!");
          if (hasModal) config.modal.hideModal();
          return dispatch(getAllFarmersWithParams(config.filterParams));
        } else if (response.status === 401) {
          toast.error("You are not authorized to do that!");
        }
        dispatch({ type: LOAD_FARMERS_DATA, payload: false });
      })
      .catch(err => {
        handleActionError(err, "Update Farmer Error");
        dispatch({ type: LOAD_FARMERS_DATA, payload: false });
        if (hasFormOptions) config.formOptions.setSubmitting(false);
      });
  };

export const resetPaginationValues = () => dispatch => {
  dispatch({
    type: RESET_PAGINATION_VALUES_FARMERS,
  });
};
export const changePageSize = perPage => dispatch => {
  dispatch({
    type: CHANGE_PAGE_SIZE,
    payload: perPage,
  });
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize.value;
  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};
export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  dispatch({
    type: HANDLE_PAGE_CHANGE,
    payload: pageNumber,
  });
  dispatch(handleOffset(pageNumber, pageSize.value));
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY,
    payload: arrayOfInfo,
  });
};

export const getFarmerOverviewByRange =
  (startDate, endDate, season, { setLoading }) =>
  dispatch => {
    legacyAuthChecker();
    const params = {
      startDate,
      endDate,
      season,
    };

    return farmers
      .get("/date-range/count", { params })
      .then(farmerOverviewData => {
        dispatch({
          type: GET_FARMER_OVERVIEW,
          payload: farmerOverviewData.data.data,
        });
        setLoading(false);
      })
      .catch(__err => {
        setLoading(false);
        toast.error("Something went wrong fetching Farmers Overview, try refreshing the page");
      });
  };

export const getFarmerOverviewByDate =
  (date, season, { setLoading }) =>
  dispatch => {
    legacyAuthChecker();
    const params = {
      date,
      season,
    };
    return farmers
      .get("/date/count", { params })
      .then(farmerOverviewData => {
        dispatch({
          type: GET_FARMER_OVERVIEW,
          payload: farmerOverviewData.data.data,
        });
        setLoading(false);
      })
      .catch(__err => {
        setLoading(false);
        toast.error("Something went wrong fetching Farmers, try refreshing the page");
      });
  };

export const transferFarmerToAgent =
  (values = [], config = {}) =>
  async dispatch => {
    try {
      const params = {
        agentIdTo: config.agentTo,
        agentIdFrom: config.agentFrom,
      };
      // const farmersGroups = values.map(farmer => farmer.group_id);
      dispatch({ type: LOAD_FARMERS_DATA, payload: true });
      await farmers.post("transferFarmer", values, { params });
      toast.success(`${values.length} Farmer Groups transfered to ${config.agent.first_name}`);
      dispatch({ type: LOAD_FARMERS_DATA, payload: false });
      if (_.isFunction(config?.onSuccess)) {
        config.onSuccess();
      }
      if (config?.filter) {
        // opt out of fetching the list
        return dispatch(getAllFarmersWithParams(config.filter));
      }
    } catch (transferFarmerToAgentErr) {
      dispatch({ type: LOAD_FARMERS_DATA, payload: false });
      handleActionError(transferFarmerToAgentErr);
    }
  };

//reducer
function middlewareFilterForFarmer(state, action) {
  try {
    let farmerRecord = action.payload;
    return {
      ...state,
      farmerData: farmerRecord, //action.payload,
      totalFarmersCount: action.totalFarmersCount,
      farmersMeta: action.farmersMeta,
      loading: false,
    };
  } catch (error) {
    console.log({ error });
  }
}

const initialState = {
  loading: false,
  farmerData: [],
  farmersMeta: 0,
  loadingSearch: false,
  farmerResetData: [],
  totalFarmersCount: 0,
  newFarmersCount: 0,
  oldFarmersCount: 0,
  groupSize: 0,
  groupInfo: [],
  averageGroupSize: 0,
  groups: [],
  groupsChartData: [],
  farmersChartData: [],
  farmersPerAgent: [], //farmers/agent
  newFarmers: [],
  oldFarmers: [], // diff by oldmember tag using the .filter()
  averagefarmersPerAgent: 0,
  agentInvolvedCount: 0,
  agentInvolved: 0,
  agentFarmer: {},
  pages: 1,
  pageSize: { label: "10", value: 10 },
  pageNumber: 1,
  total: 0,
  offset: 0,
  info: [],
  filteredData: [],
  searchTerm: "",
  date: new Date(),
  farmerOverviewData: [],
  farmerOverviewCount: 0,
  filteredByDate: [],
  filteredPages: 0,
  filteredTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FARMERS:
      return {
        ...state,
        pages: action.pages,
        total: action.total,
      };
    case GET_PAGINATED_FILTER_FARMERS:
      return {
        ...state,
        farmerTableData: action.payload,
        pages: action.pages,
        total: action.total,
      };
    case GET_ALL_FILTER_FARMERS:
      return middlewareFilterForFarmer(state, action);
    case GET_ALL_FILTER_FARMERS_MODEL_COUNT: {
      let {
        totalFarmers,
        newFarmers,
        oldFarmers,
        agentInvolvedCount,
        averageFarmersPerAgent,
        groupSize,
        averageGroupSize,
        graphData, //with just role & created_at meta
      } = action.payload;
      let groupInfo = graphData?.filter(g => g?.role === "lead_farmer");
      let groupsChartData = generateXYData(groupInfo),
        farmersChartData = generateXYData(graphData);

      return {
        ...state,
        totalFarmersCount: totalFarmers,
        newFarmersCount: newFarmers,
        oldFarmersCount: oldFarmers,
        agentInvolvedCount: agentInvolvedCount,
        averageFarmersPerAgent: averageFarmersPerAgent,
        groupSize: groupSize,
        groupInfo,
        groupsChartData,
        farmersChartData,
        averageGroupSize: averageGroupSize,
      };
    }
    case LOAD_FARMERS_DATA:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_SEARCH:
      return {
        ...state,
        loadingSearch: action.payload,
      };
    case CHANGE_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case HANDLE_PAGE_CHANGE:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY:
      return { ...state, info: action.payload };
    case FILTER_FARMERS_DATA: {
      const searchTerm = new RegExp(action.payload, "i");
      // prettier-ignore
      const filteredData = state
      .farmerData
      .filter((farmer) => Object.values(farmer)
        .find(farmer => searchTerm.test(farmer)));
      return { ...state, filteredData };
    }
    case SEARCH_TERM: {
      const term = new RegExp(action.payload, "i");
      // prettier-ignore
      const result = state
      .farmerData
      .filter((farmer) => Object.values(farmer)
        .find(farmer => term.test(farmer)));
      return { ...state, searchTerm: action.payload, filteredData: result };
    }
    case HANDLE_DATE_FILTER:
      return {
        ...state,
        date: action.payload.date,
        filteredByDate: action.payload.filteredByDate,
        filteredPages: action.payload.filteredPages,
        filteredTotal: action.payload.filteredTotal,
      };
    case RESET_PAGINATION_VALUES_FARMERS:
      return { ...state, pageNumber: 1, offset: 0 };
    case GET_FARMER_OVERVIEW:
      return {
        ...state,
        farmerOverviewData: action.payload.farmers,
        farmerOverviewCount: action.payload.foundFarmersCount,
      };
    default:
      return state;
  }
};

// selector functions

export const getFarmersData = state => state.legacyFarmer.farmerData;
export const getLoadingFarmers = state => state.legacyFarmer.loading;
export const getFarmersMeta = state => state.legacyFarmer.farmersMeta;
export const getLoadingSearch = state => state.legacyFarmer.loadingSearch;
export const getFarmerResetData = state => state.legacyFarmer.farmerResetData;
export const getTotalFarmersCount = state => state.legacyFarmer.totalFarmersCount;
export const getNewFarmersCount = state => state.legacyFarmer.newFarmersCount;
export const getOldFarmersCount = state => state.legacyFarmer.oldFarmersCount;
export const getGroupSize = state => state.legacyFarmer.groupSize;
export const getGroupInfo = state => state.legacyFarmer.groupInfo;
export const getAverageGroupSize = state => state.legacyFarmer.averageGroupSize;
export const getGroups = state => state.legacyFarmer.groups;
export const getGroupsChartData = state => state.legacyFarmer.groupsChartData;
export const getFarmersChartData = state => state.legacyFarmer.farmersChartData;
export const getFarmersPerAgent = state => state.legacyFarmer.farmersPerAgent;
export const getNewFarmers = state => state.legacyFarmer.newFarmers;
export const getOldFarmers = state => state.legacyFarmer.oldFarmers;
export const getAveragefarmersPerAgent = state => state.legacyFarmer.averagefarmersPerAgent;
export const getAgentInvolved = state => state.legacyFarmer.agentInvolved;
export const getAgentInvolvedCount = state => state.legacyFarmer.agentInvolvedCount;
export const getAgentFarmer = state => state.legacyFarmer.agentFarmer;
export const getPages = state => state.legacyFarmer.pages;
export const getPageSize = state => state.legacyFarmer.pageSize;
export const getPageNumber = state => state.legacyFarmer.pageNumber;
export const getTotal = state => state.legacyFarmer.total;
export const getOffset = state => state.legacyFarmer.offset;
export const getInfo = state => state.legacyFarmer.info;
export const getFilteredData = state => state.legacyFarmer.filteredData;
export const getSearchTerm = state => state.legacyFarmer.searchTerm;
export const getDate = state => state.legacyFarmer.date;
export const getFarmerOverviewData = state => state.legacyFarmer.farmerOverviewData;
export const getFarmerOverviewCount = state => state.legacyFarmer.farmerOverviewCount;
export const getFilteredByDate = state => state.legacyFarmer.filteredByDate;
export const getFilteredPages = state => state.legacyFarmer.filteredPages;
export const getFilteredTotal = state => state.legacyFarmer.filteredTotal;
