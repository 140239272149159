import React from "react";
import { Suspense } from "react";
import components from "./components";
import { ErrorState } from "components/appState";

export function Skeleton({ type, ...props }) {
  const Component = components[type];

  if (!Component) {
    console.error(`AOSUIError: Skeleton Component type "${type}" not found.`);
    return null; // Avoid rendering if the component doesn't exist
  }

  if (type === "image") {
    return <Component src={props.src} fallback={props.fallback} {...props} />;
  }

  return (
    <ErrorState>
      <Suspense fallback={<>Loading...</>}>
        <Component {...props} />
      </Suspense>
    </ErrorState>
  );
}
