import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { AI_API } from "services/api/aiApiService";

export function useTAIConversations() {
  return useQuery({
    queryKey: [rqKeys.ai.fetchConversations],
    queryFn: async () => {
      const res = await AI_API.fetchConversations();
      return res.data.data;
    },
  });
}
