import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { sessionStorageKeys } from "constants/storageConstants";
import { getCurrentOrganization, getLoadingOrganizations } from "features/v1/organization";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MulitTenant_API } from "services/api/multitenantService";
import { getOrg, getOrgName, getSenderId } from "services/v1/authService";

export function useOrgData() {
  const dispatch = useDispatch();

  const orgId = sessionStorage.getItem(sessionStorageKeys.ORG);

  const { data, ...restQueryDetails } = useQuery({
    queryFn: MulitTenant_API.getAllOrganizations,
    queryKey: [rqKeys.application.getAllOrganizations],
  });

  let currentOganization = useMemo(() => {
    if (!data?.data) {
      return {};
    }
    const _currentOrganization = data?.data?.find?.(org => `${org._id}` === orgId);
    if (!_currentOrganization) {
      return {};
    }
    return _currentOrganization;
  }, [data?.data, orgId]);

  let loadingOrganizations = useSelector(getLoadingOrganizations);

  let isMultiCountry = useMemo(() => {
    if (currentOganization) {
      //   const _currentOrg = getOrgById(allOrgs, orgId);
      // if(_currentOrg && currentOrg.count)
      return currentOganization.country?.length > 1;
    }
    return null;
  }, [currentOganization]);

  let orgCountries = useMemo(() => {
    if (currentOganization) {
      return currentOganization.country;
    }
    return null;
  }, [currentOganization]);

  let orgName = useMemo(() => currentOganization?.name || getOrgName(), [currentOganization]);
  let orgSubdomain = useMemo(() => currentOganization?.tenantInfo?.subdomain || "", [currentOganization]);

  useEffect(() => {
    console.log({ currentOganization });
    if (!currentOganization) {
      dispatch(getCurrentOrganization());
    }
  }, []);

  return {
    loading: loadingOrganizations,
    orgId: getOrg(),
    orgName,
    orgSubdomain,
    isMultiCountry,
    senderId: getSenderId()?.id || "",
    senderIdStatus: getSenderId()?.status || "",
    currentOrg: currentOganization,
    orgCountries: orgCountries || [],
    ...restQueryDetails,
  };
}
