import React from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";
import InnerRoutes, { PrivateRoute } from "./CustomRoutes";
import { Navigate, Route, Routes } from "react-router-dom";
import { AUTH_ROUTES_LIST } from "./authRoutes";
import { DASHBOARD_ROUTE_LIST } from "./dashboardRoutes";
import { legacyAuthChecker } from "services/v1/authService";
import GGPage from "views/pages/GGPage";
import Err404Page from "views/pages/errorPages/Err404Page";

export const INDEX_ROUTES_LIST = [
  {
    auth: false,
    path: "/auth/*",
    name: "Welcome - Admin Dashboard",
    layout: true,
    element: <AuthLayout />,
    children: AUTH_ROUTES_LIST,
  },
  {
    auth: true,
    path: "/dashboard/*",
    layout: true,
    name: "Home - Admin Dashboard",

    element: <DashboardLayout />,
    children: DASHBOARD_ROUTE_LIST,
  },
  {
    path: "gg",
    name: "God Mode > Experimental",
    icon: "settings",
    invisible: true,
    showAtBottom: true,
    element: <GGPage />,
  },
  {
    auth: false,
    path: "*",
    layout: false,
    name: "Not Found - Admin Dashboard",
    element: <Err404Page />,
  },

  // { path: "/redirect", name: "Redirect", layout: false, element: <Redirect /> },
];

const IndexRoutes = () => {
  let userAuthenticated = legacyAuthChecker();
  return (
    <Routes>
      {/* for now all we can do from the root is redirect to the 2 branches of the site */}
      <Route index element={<Navigate to={{ pathname: userAuthenticated ? "/dashboard/" : "/auth/login" }} />} />
      {INDEX_ROUTES_LIST.map((indexRoute, key) => {
        // render Layout index routes
        if (indexRoute?.layout) {
          return (
            <Route
              key={key}
              path={indexRoute.path}
              element={
                indexRoute.auth === true ? (
                  <PrivateRoute redirectTo="/auth/login">
                    <InnerRoutes parent={indexRoute} routes={indexRoute.children} />
                  </PrivateRoute>
                ) : (
                  <InnerRoutes parent={indexRoute} routes={indexRoute.children} />
                )
              }
            />
          );
        }
        return (
          <Route
            path={indexRoute.path}
            key={key}
            element={
              indexRoute.auth === true ? (
                <PrivateRoute redirectTo="/auth/login">{indexRoute.element}</PrivateRoute>
              ) : (
                <>{indexRoute.element}</>
              )
            }
            errorElement={<Err404Page />}
          />
        );
      })}
      <Route path="*" element={<v />} />
    </Routes>
  );
};

export default IndexRoutes;
