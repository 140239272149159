import { FormInput } from "components/inputs";
import { VisibilityWrapper } from "components/layout";
import { CountrySelect, GenderSelect, LGASelect, RegionSelect, RoleSelect, StateSelect } from "components/select";
import { submitAdmincredentials } from "features/v1/auth";
import { Form, Formik } from "formik";
import { useOrgData } from "hooks";
import { Box, Button, Flex, Text } from "organic";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createAdminValidator } from "validators/createAdminValidator";

const CreateAdminForm = ({ modal }) => {
  const [addMiddleName, setAddMiddleName] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const dispatch = useDispatch();
  const { orgId } = useOrgData();
  console.log(orgId);

  const handleRoleSelect = (roleValue, setFieldValue) => {
    if (roleValue === "regionalManager") setFieldValue("zone", "all");
    if (roleValue === "subAdmin") {
      setFieldValue("region", "all");
      setFieldValue("zone", "all");
    }
    setFieldValue("role", roleValue);
  };

  return (
    <div>
      <Formik
        initialValues={{
          role: "",
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          password: "",
          gender: "",
          state: "",
          lga: "",
          region: "",
          zone: "all",
          organization: orgId,
        }}
        validationSchema={createAdminValidator}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          dispatch(submitAdmincredentials(values, { setSubmitting, setErrors }));
        }}
      >
        {({ values, handleChange, isSubmitting, errors, setFieldValue }) => {
          return (
            <Form>
              <Flex flexDirection="column" style={{ gap: "16px" }}>
                <Box>
                  <Text font="smMedium" color="error500">
                    {errors?.message}
                  </Text>
                </Box>
                <Box>
                  <FormInput name="first_name" label="First Name" value={values.first_name} onChange={handleChange} />
                </Box>
                <VisibilityWrapper display="block" isVisible={addMiddleName}>
                  <Box>
                    <FormInput
                      name="middle_name"
                      label="Middle Name"
                      value={values.middle_name}
                      onChange={handleChange}
                    />
                  </Box>
                </VisibilityWrapper>
                <Box>
                  <input
                    type="checkbox"
                    checked={addMiddleName}
                    onChange={() => {
                      setAddMiddleName(isChecked => {
                        if (isChecked) {
                          setFieldValue("middle_name", undefined);
                          return false;
                        }
                        return true;
                      });
                    }}
                  />
                  <Text as="label" ml="8px">
                    Add Middle Name
                  </Text>
                </Box>

                <Box>
                  <FormInput value={values.last_name} onChange={handleChange} name="last_name" label="Last Name" />
                </Box>
                <Box>
                  <FormInput
                    label="Work Email Address"
                    name="email"
                    required
                    value={values.email}
                    onChange={handleChange}
                    autoComplete="new-email"
                  />
                </Box>
                <Box>
                  <FormInput
                    name="phone_number"
                    required
                    label="Primary Phone Number"
                    value={values.phone_number}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <FormInput
                    name="password"
                    label="Password"
                    required
                    value={values.password}
                    onChange={handleChange}
                    autoComplete="new-password"
                    type="password"
                  />
                </Box>
                <Box>
                  <GenderSelect name="gender" label="Gender" required onChange={val => setFieldValue("gender", val)} />
                </Box>
                <Box>
                  <RoleSelect
                    name="role"
                    label="Role"
                    required
                    onChange={val => handleRoleSelect(val, setFieldValue)}
                    value={values.role}
                  />
                </Box>
                <Box>
                  <RegionSelect
                    name="region"
                    label="Region"
                    required
                    onChange={val => setFieldValue("region", val)}
                    disabled={values.role === "subAdmin"}
                    colorScheme="default"
                  />
                </Box>
                <Box>
                  <CountrySelect onChange={setSelectedCountry} />
                </Box>
                <Box>
                  <StateSelect
                    name="state"
                    label="State"
                    countryName={selectedCountry}
                    required
                    onChange={val => setFieldValue("state", val)}
                  />
                </Box>
                <Box>
                  <LGASelect
                    name="lga"
                    label="LGA"
                    required
                    state={values.state}
                    countryName={selectedCountry}
                    value={values.lga}
                    onChange={val => setFieldValue("lga", val)}
                  />
                </Box>
                <Flex gap="1rem">
                  <Button onClick={modal.hideModal} colorScheme="secondaryGray" type="button">
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Save
                  </Button>
                </Flex>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAdminForm;
