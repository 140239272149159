// import Axios from "axios";
// import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";
import farmers from "api/farmers";
import { legacyAuthChecker } from "../../services/v1/authService";
import farmerSeason from "api/farmerseason";

const GET_CLEARED_GROUPS_DATA = "GET_CLEARED_GROUPS_DATA";
const LOAD_GET_CLEARED_GROUPS = "LOAD_GET_CLEARED_GROUPS";
const GET_FARMERS_FOR_CLEARANCE_DATA = "GET_FARMERS_FOR_CLEARANCE_DATA";
const LOAD_GET_FARMERS_FOR_CLEARANCE = "LOAD_GET_FARMERS_FOR_CLEARANCE";
const LOAD_SEARCH_FARMERS_FOR_CLEARANCE = "LOAD_SEARCH_FARMERS_FOR_CLEARANCE";
const LOAD_SEARCH_CLEARED_GROUPS = "LOAD_SEARCH_CLEARED_GROUPS";

export const searchAllFarmersForClearanceWithParams = (params, config) => async dispatch => {
  try {
    dispatch({ type: LOAD_SEARCH_FARMERS_FOR_CLEARANCE, payload: true });
    const farmerSearchData = await farmerSeason.get("search", { params, siganl: config.abortController.signal });

    dispatch({
      type: GET_FARMERS_FOR_CLEARANCE_DATA,
      payload: farmerSearchData?.data?.data || [],
      farmersMeta: farmerSearchData?.data?.meta || {},
    });
    dispatch({ type: LOAD_SEARCH_FARMERS_FOR_CLEARANCE, payload: false });
  } catch (searchAllFarmersForClearanceWithParamsError) {
    dispatch({ type: LOAD_SEARCH_FARMERS_FOR_CLEARANCE, payload: false });
    handleActionError(searchAllFarmersForClearanceWithParamsError);
  } finally {
    dispatch({ type: LOAD_SEARCH_FARMERS_FOR_CLEARANCE, payload: false });
  }
};

export const getAllClearedFarmersWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_GET_CLEARED_GROUPS, payload: true });
    const resData = await farmers.get("getAllClearFarmerWithParams", { params });

    dispatch({
      type: GET_CLEARED_GROUPS_DATA,
      payload: resData.data.data,
      clearedGroupsMeta: resData?.data?.meta || {},
    });

    dispatch({ type: LOAD_GET_CLEARED_GROUPS, payload: false });
  } catch (getAllClearedFarmersWithParamsError) {
    dispatch({ type: LOAD_GET_CLEARED_GROUPS, payload: false });
    handleActionError(getAllClearedFarmersWithParamsError);
  }
};

export const searchClearedGroupWithParams =
  (params, { abortController }) =>
  async dispatch => {
    try {
      legacyAuthChecker();
      dispatch({ type: LOAD_SEARCH_CLEARED_GROUPS, payload: true });
      const groupSearchData = await farmers.get("searchClearFarmer", { params, signal: abortController.signal });

      dispatch({
        type: GET_CLEARED_GROUPS_DATA,
        payload: groupSearchData.data.data,
        farmersMeta: groupSearchData?.data?.meta || {},
      });

      dispatch({ type: LOAD_SEARCH_CLEARED_GROUPS, payload: false });
    } catch (searchClearedGroupWithParamsError) {
      dispatch({ type: LOAD_SEARCH_CLEARED_GROUPS, payload: false });
      handleActionError(searchClearedGroupWithParamsError);
    }
  };

const initialState = {
  clearedGroupsData: [],
  clearedGroupsMeta: {},
  farmersForClearanceData: [],
  farmersForClearanceMeta: {},
  loadingFarmersForClearance: false,
  loadingClearedGroups: false,
  loadingSearchFarmersForClearance: false,
  loadingSearchClearedGroups: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FARMERS_FOR_CLEARANCE_DATA:
      return {
        ...state,
        farmersForClearanceData: action.payload,
        farmersForClearanceMeta: action.farmersMeta,
      };
    case GET_CLEARED_GROUPS_DATA:
      return middlewareClearedGroupsData(state, action);
    case LOAD_GET_CLEARED_GROUPS:
      return {
        ...state,
        loadingClearedGroups: action.payload,
      };
    case LOAD_GET_FARMERS_FOR_CLEARANCE:
      return {
        ...state,
        loadingFarmersForClearance: action.payload,
      };
    case LOAD_SEARCH_CLEARED_GROUPS:
      return {
        ...state,
        loadingSearchClearedGroups: action.payload,
      };
    case LOAD_SEARCH_FARMERS_FOR_CLEARANCE:
      return {
        ...state,
        loadingSearchFarmersForClearance: action.payload,
      };
    default:
      return state;
  }
};

function middlewareClearedGroupsData(state, action) {
  try {
    let clearedFarmers = action.payload;
    return {
      ...state,
      clearedGroupsData: clearedFarmers,
      clearedGroupsMeta: action.clearedGroupsMeta,
    };
  } catch (error) {
    console.log({ error });
  }
}

// selector function
export const getFarmersForClearanceData = state => state.legacyFarmerClearance.farmersForClearanceData;
export const getFarmersForClearanceMeta = state => state.legacyFarmerClearance.farmersForClearanceMeta;
export const getLoadingFarmersForClearance = state => state.legacyFarmerClearance.loadingFarmersForClearance;
export const getClearedFarmersData = state => state.legacyFarmerClearance.clearedGroupsData;
export const getClearedFarmersMeta = state => state.legacyFarmerClearance.clearedGroupsMeta;
export const getLoadingClearedGroups = state => state.legacyFarmerClearance.loadingClearedGroups;
export const getLoadingSearchClearedGroups = state => state.legacyFarmerClearance.loadingSearchClearedGroups;
export const getLoadingSearchFarmersForClearance = state =>
  state.legacyFarmerClearance.loadingSearchFarmersForClearance;
