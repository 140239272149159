import React from "react";
import { Navigate } from "react-router-dom";

import Loader from "components/v1/Loader/RedirectLoader";
import { Flex } from "organic";

export default function Redirect({ pathTo }) {
  return (
    <Flex className="p-5 my-5 text-center">
      <Loader />

      <Flex flexDirection="row" flex="1" alignItems="center">
        <Flex flexDirection="column" mb={4}>
          <span className="display-4 text-muted">Redirecting, please wait...</span>
        </Flex>
      </Flex>
      <Navigate to={{ pathname: pathTo }} />
    </Flex>
  );
}
