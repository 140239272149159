import { useQuery } from "@tanstack/react-query";
import { authChecker } from "services/shared/authService";
import { AOSClientError } from "services/shared/AOSErrorService";
import { rqKeys } from "constants/reactQueryKeys";
import Auth_API from "services/api/authApiService";
import { setUserData } from "features/v1/auth";
import { useDispatch } from "react-redux";

export function useUserData() {
  const dispatch = useDispatch();
  let enabled = authChecker();
  async function fetcher() {
    try {
      enabled = authChecker();
      const res = await Auth_API.fetchUser();
      dispatch(setUserData(res.data.data));
      console.log({ user: res.data.data });
      return res.data.data;
    } catch (fetchUserDataErr) {
      console.log(fetchUserDataErr);
      const bug = new AOSClientError("Failed to fetch user data");
      bug.report();
    }
  }

  return useQuery({ queryFn: fetcher, queryKey: [rqKeys.application.getUserData], enabled });
}
