import { Flex, Text } from "organic";
import React, { useMemo } from "react";
import { TAIInput } from "./TAIInput";
import { useDashboardData } from "hooks";
import { TAIHistory } from "./TAIHistory";
import { TAIConversation } from "./TAIConversation";

// const recentConversations = [
//   { id: "1", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", title: "Speak Any Language: Translate phrases instantly." },
//   { id: "3", title: "Speak Any Language: Translate phrases instantly." },
// ];
// const sugestedPrompts = [
//   { id: "1", prompt: "Speak Any Language: Translate phrases instantly." },
//   { id: "2", prompt: "Speak Any Language: Translate phrases instantly." },
//   { id: "3", prompt: "Speak Any Language: Translate phrases instantly." },
//   { id: "4", prompt: "Speak Any Language: Translate phrases instantly." },
//   { id: "5", prompt: "Speak Any Language: Translate phrases instantly." },
//   { id: "6", prompt: "Speak Any Language: Translate phrases instantly." },
// ];

export const TAIMain = () => {
  const { dashboardState } = useDashboardData();

  const currentView = useMemo(() => dashboardState.currentTAIView, [dashboardState.currentTAIView]);

  return (
    <Flex position="relative" flexDirection="column" overflowY="hidden" p="1rem" gap="2rem" height="520px">
      <Flex flexDirectio="column" height="400px" width="100%" overflowY="auto">
        {currentView === "history" ? <TAIHistory /> : null}
        {currentView === "conversation" ? <TAIConversation /> : null}
      </Flex>
      <Flex
        flexDirection="column"
        height="fit-content"
        position="static"
        bottom="0rem"
        left="1rem"
        right="1rem"
        bg="white"
        justifyContent="space-between"
      >
        <TAIInput />
        <Text font="xsTiny" color="gray600" textAlign="center">
          Privacy Policy. Legal Notice.Copyright © 2024 Thrive AI Chat. All rights reserved.
        </Text>
      </Flex>
    </Flex>
  );
};
