import { EmptyState } from "components/appState";
import { useTAIConversations } from "hooks/app/TAI";
import { Flex, Text } from "organic";
import React from "react";

export const TAIHistory = ({ suggestedPrompts }) => {
  const { isLoading, data: recentConversations } = useTAIConversations();
  return (
    <Flex width="100%" flexDirection="column" gap="1rem">
      <Flex flexDirection="column" width="100%">
        {recentConversations?.length ? (
          <>
            <Text>Recent</Text>
            <Flex flexDirection="column" gap="14px" width="100%" maxHeight="300px" overflowY="auto">
              {recentConversations?.map?.(conversation => (
                <Flex
                  backgroundColor="gray100"
                  width="100%"
                  borderRadius="6px"
                  px="20px"
                  py="14px"
                  key={conversation._id}
                >
                  <Text font="smRegular" color="gray800" fontWeight="400">
                    {conversation.prompt}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </>
        ) : isLoading ? (
          <EmptyState title="Loading Conversations..." subtitle="Checking for your conversations history" />
        ) : (
          <EmptyState title="Recent Conversations" subtitle="All previous prompts will show up here" />
        )}
      </Flex>
      <Flex flexDirection="column" width="100%">
        <Text>Suggestions</Text>
        <Flex flexDirection="column" gap="14px" width="100%">
          {suggestedPrompts?.length ? (
            suggestedPrompts?.map?.(conversation => (
              <Flex backgroundColor="gray100" width="100%" borderRadius="6px" px="20px" py="14px" key={conversation.id}>
                <Text font="smRegular" color="gray800" fontWeight="400">
                  {conversation.prompt}
                </Text>
              </Flex>
            ))
          ) : (
            <Text font="xsRegular" color="gray600">
              No Suggestions at the moment
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

TAIHistory.defaultProps = {
  recentConversations: [],
  suggestedPrompts: [],
};
