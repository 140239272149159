import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";
import { rootReducer } from "features";

const initialState = {};

const middleware = [thunk];

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
);

export default store;
