import styled, { css } from "styled-components/macro";
import shouldForwardProp from "@styled-system/should-forward-prop";
import { color, space, typography } from "styled-system";
import typographyMixins from "organic/theme/mixins/_typography";

const Text = styled.p.withConfig({
  shouldForwardProp,
})`
  ${typography}
  ${color}
  ${space}
  ${props => {
    if (Object.keys(typographyMixins).includes(props.font)) {
      return typographyMixins[props.font];
    }
    return "";
  }}

  ${props =>
    props.textTransform
      ? css`
          text-transform: ${props.textTransform};
        `
      : null}
`;

Text.defaultProps = {
  font: "mdRegular",
};

export { Text };
