import React, { useEffect } from "react";
import { createRole, getAllRoles, getLoadingRoles } from "features/v1/role";
import { Form, Formik } from "formik";
import { scopeOptions } from "services/v1/rolesAndPermissionsService";
import { useDispatch, useSelector } from "react-redux";
import { initialCreateRoleValues } from "views/v1/Forms/Settings/settingsInitialValues";
import { createRoleValidationSchema } from "views/v1/Forms/Settings/settingsValidationSchemas";
import _ from "lodash";
import { Box, Button, Input } from "organic";
import { Select } from "components/select";
import { FormInput } from "components/inputs";

function CreateRoleForm({ disclosure }) {
  const dispatch = useDispatch();

  let loadingRoles = useSelector(getLoadingRoles);

  const handleCreateRole = (values, formOptions) => {
    values.name = _.camelCase(values.name);
    values.slug = _.camelCase(values.name);
    dispatch(
      createRole(values, {
        formOptions,
        onSuccess() {
          disclosure.onClose();
        },
      })
    );
  };
  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialCreateRoleValues}
        validationSchema={createRoleValidationSchema}
        onSubmit={handleCreateRole}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box my={40}>
              <Box mb={4}>
                <FormInput placeholder="Role Name" label="Name" required name="name" />
              </Box>
              <Box mb={4}>
                <Input
                  value={_.camelCase(values.name)}
                  placeholder="Start Typing a role name"
                  label="Slug"
                  isDisabled
                  readOnly
                />
              </Box>

              <Box mb={4}>
                <Select
                  isMulti
                  isClearable
                  placeholder="Select Multiple Permissions for new Role"
                  label="Permissions"
                  name="scope"
                  options={scopeOptions()}
                  onChange={scopeArr => {
                    if (!scopeArr) {
                      setFieldValue("scope", []);
                      return;
                    }
                    setFieldValue(
                      "scope",
                      scopeArr.map(scope => scope.value)
                    );
                  }}
                />
              </Box>

              <Box mb={4}>
                <Button colorScheme="primary" display="block" type="submit" disabled={loadingRoles}>
                  Create
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateRoleForm;

// import CustomButton from "components/v1/Button/CustomButton";
// import CustomCollapse from "components/v1/Collapse/CustomCollapse";
// import CustomSelect from "components/v1/CustomSelect/CustomSelect";
// import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
// import CustomFormField from "components/v1/Forms/CustomFormField";
// import FormGridRow from "components/v1/Forms/FormGridRow";
// import CustomCheckbox from "components/v1/Input/CustomCheckbox";
// import {
//   addScope,
//   createRole,
//   getAllRoles,
//   // getAllRoles,
//   getAllRolesData,
//   // getBasicRolesData,
//   getLoadingRoles,
//   removeScope,
// } from "features/role";
// import { Form, Formik } from "formik";
// import _ from "lodash";
// import { nanoid } from "nanoid";
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { resources, scopeOptions } from "services/rolesAndPermissionsService";
// import { initialCreateRoleValues } from "views/v1/Forms/Settings/settingsInitialValues";
// import { createRoleValidationSchema } from "views/v1/Forms/Settings/settingsValidationSchemas";

// const RolesAndPermission = () => {
//     const dispatch = useDispatch();

//     // let rolesData = useSelector(getAllRolesData);
//     let loadingRoles = useSelector(getLoadingRoles);

//     const handleCreateRole = (values, formOptions) => {
//       values.name = _.camelCase(values.name);
//       dispatch(createRole(values, { formOptions }));
//     };
//       useEffect(() => {
//           dispatch(getAllRoles());
//         }, []);

//   const toggleScope = (canDo, config = {}) => {
//     if (canDo === true) {
//       dispatch(addScope({ scope: [`${config.resource}.${config.scope}`] }, { params: { slug: config.slug } }));
//       return;
//     }
//     dispatch(removeScope({ scope: [`${config.resource}.${config.scope}`] }, { params: { slug: config.slug } }));
//   };

//   return (
//     <div>
//       <CustomCollapse loading={loadingRoles} title="Create a Role" centerTitle>

//       </CustomCollapse>
//       <div className="mt-4">
//         <DataGridTitle className="" title="All Roles" />

//         {rolesData.map((basicRole, roleIndex) => (
//           <CustomCollapse
//             loading={loadingRoles}
//             title={basicRole?.name || `User Role: ${roleIndex + 1}`}
//             key={nanoid()}
//           >
//             <Container>
//               <Row className="p-2 my-2 font-weight-bold bg-light border-bottom rounded-top">
//                 <Col xs={8} sm={4}>
//                   Resource
//                 </Col>
//                 <Col xs={2} sm={4} className="text-center">
//                   Read Access
//                 </Col>
//                 <Col xs={2} sm={4} className="text-center">
//                   Write Access
//                 </Col>
//               </Row>
//               <div className="list-striped">
//                 {/* special row for super admin  */}
//                 {basicRole.scope.includes("all.read") || basicRole.scope.includes("all.write") ? (
//                   <Row key={nanoid()} className="ca-font-regular row align-items-center">
//                     <Col xs={8} sm={4}>
//                       All Resources
//                     </Col>
//                     <Col xs={2} sm={4} className="text-center checkbox-col">
//                       <CustomCheckbox
//                         onChange={canRead => {
//                           toggleScope(canRead, { slug: basicRole.slug, resource: "all", scope: "read" });
//                         }}
//                         defaultValue={basicRole?.scope?.includes(`all.read`)}
//                         disabled
//                       />
//                     </Col>
//                     <Col xs={2} sm={4} className="text-center checkbox-col">
//                       <CustomCheckbox
//                         onChange={canWrite => {
//                           toggleScope(canWrite, { slug: basicRole.slug, resource: "all", scope: "write" });
//                         }}
//                         defaultValue={basicRole?.scope?.includes(`all.write`) || basicRole.scope.includes("all.write")}
//                         disabled
//                       />
//                     </Col>
//                   </Row>
//                 ) : null}

//                 {Object.entries(resources).map(resource => (
//                   <Row key={nanoid()} className="ca-font-regular row align-items-center">
//                     <Col xs={8} sm={4}>
//                       {resource[1]}
//                     </Col>
//                     <Col xs={2} sm={4} className="text-center checkbox-col">
//                       <CustomCheckbox
//                         onChange={canRead => {
//                           toggleScope(canRead, { slug: basicRole.slug, resource: resource[0], scope: "read" });
//                         }}
//                         defaultValue={
//                           basicRole?.scope?.includes(`${resource[0]}.read`) || basicRole.scope.includes("all.read")
//                         }
//                       />
//                     </Col>
//                     <Col xs={2} sm={4} className="text-center checkbox-col">
//                       <CustomCheckbox
//                         onChange={canWrite => {
//                           toggleScope(canWrite, { slug: basicRole.slug, resource: resource[0], scope: "write" });
//                         }}
//                         defaultValue={
//                           basicRole?.scope?.includes(`${resource[0]}.write`) || basicRole.scope.includes("all.write")
//                         }
//                       />
//                     </Col>
//                   </Row>
//                 ))}
//               </div>
//             </Container>
//           </CustomCollapse>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default RolesAndPermission;
