import React, { useState } from "react";
import { Icon, Box, Text, organicTheme, Flex } from "organic";
import { StyledLabel, StyledInput, StyledInputGroup } from "./styledInput";
import PropTypes from "prop-types";
import { isFunction } from "utils";
import CopyToClipboard from "react-copy-to-clipboard";

const InputPropTypes = {
  rightAddon: PropTypes.node,
  hint: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.node,
  errorText: PropTypes.string,
  inputProps: PropTypes.object,
  type: PropTypes.oneOf([
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]),
  isInvalid: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  palceholder: PropTypes.any,
  leftAddon: PropTypes.node,
};

const ShowPasswordToggle = ({ showPassword, handleShowPassword }) => (
  <Box p={3}>
    {showPassword ? (
      <Icon icon="eye" onClick={handleShowPassword} color="gray400" size="12px" />
    ) : (
      <Icon icon="eye-off" onClick={handleShowPassword} color="gray400" size="12px" />
    )}
  </Box>
);

const Input = React.forwardRef((props, forwardedRef) => {
  const {
    leftIcon,
    rightAddon,
    hint,
    description,
    errorText,
    label,
    isInvalid,
    inputProps: inputPropProps,
    type,
    name,
    value,
    onChange,
    isDisabled,
    required: isRequired,
    isRequired: isRequiredProp, // ignore teh confusion, it will be normalized
    placeholder,
    defaultValue,
    width,
    hidePasswordToggle,
    showCopy,
    leftAddon,
    ...restProps
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(prevShowPasswordState => !prevShowPasswordState);
  };

  const inputProps = { ...inputPropProps, ...restProps };

  return (
    <Box position="relative" width={width}>
      <Box mb={2}>
        <StyledLabel fontSize={14} pb={2}>
          {label}
          {isRequired || isRequiredProp === true ? (
            <Text as="span" color="primary600">
              *
            </Text>
          ) : (
            ""
          )}
        </StyledLabel>
      </Box>
      <StyledInputGroup>
        {leftIcon && <Icon icon={leftIcon} p={3} size={16} />}
        <Box>{leftAddon || null}</Box>
        <StyledInput
          {...inputProps}
          type={showPassword ? "text" : type}
          name={name}
          ref={forwardedRef}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          required={isRequired || isRequiredProp}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
        {description ? <Icon icon="help-circle" size={16} /> : null}
        {isInvalid ? <Icon icon="alert-circle" size={16} color={organicTheme.colors.error500} /> : null}
        {type === "password" && !hidePasswordToggle ? (
          <ShowPasswordToggle showPassword={showPassword} handleShowPassword={handleShowPassword} />
        ) : undefined}
        {showCopy ? (
          <CopyToClipboard
            text={value}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
          >
            <Flex alignItems="center" cursor="pointer" height="100%" px={3} borderLeft="1px solid #d0d5dd">
              <Box pr={1}>
                <Icon icon="copy" size={16} />
              </Box>
              <Text font="smMedium">{copied ? "Copied" : "Copy"}</Text>
            </Flex>
          </CopyToClipboard>
        ) : undefined}
        <Box>{rightAddon || null}</Box>
      </StyledInputGroup>
      <Text font="smMedium" color="gray500">
        {isFunction(hint) ? hint() : hint}
      </Text>
      <Text as="span" font="smRegular" color="error700">
        {errorText || ""}
      </Text>
    </Box>
  );
});

Input.displayName = "Input";

Input.defaultProps = {
  isDisabled: false,
  onChange() {},
};
Input.propTypes = InputPropTypes;
export default Input;
