import React, { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import { DASHBOARD_ROUTE_LIST } from "routes/dashboardRoutes";
import { Box, Button, Flex, Icon, organicTheme, Text } from "organic";
import { classnames } from "utils";
import { ScopedView, SidebarNav } from ".";
import { dashboardLayoutConstants, getSidebarExpanded } from "features/v1/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Logo } from "components/common";
import cardBg from "assets/images/TAI-card-bg.png";
import { Tooltip } from "components/modal";
import { useDashboardData } from "hooks";

let ps;
export const SIDEBAR_EXPANDED_INNER_WIDTH = "263px";
export const SIDEBAR_COLLAPSED_INNER_WIDTH = "48px";

let SIDEBAR_COLLAPSED_ICON = "chevrons-right";
let SIDEBAR_EXPANDED_ICON = "chevrons-left";

const Sidebar = () => {
  const sidebarRef = useRef();

  const { dashboardState, onOpenTAI, onCloseTAI } = useDashboardData();

  let location = useLocation();
  let expanded = useSelector(getSidebarExpanded);
  const dispatch = useDispatch();

  let sidebarClassnames = classnames("sidebar", {
    "sidebar--expanded": expanded,
    "sidebar--collapsed": !expanded,
  });
  let sidebarTopClassnames = classnames("sidebar-top", {
    "sidebar-top--expanded": expanded,
    "sidebar-top--collapsed": !expanded,
  });
  let sidebarBottomClassnames = classnames("sidebar-bottom", {
    "sidebar-bottom--expanded": expanded,
    "sidebar-bottom--collapsed": !expanded,
  });

  let sidebarToggleIcon = expanded ? SIDEBAR_EXPANDED_ICON : SIDEBAR_COLLAPSED_ICON;

  const toggleExpanded = () => {
    dispatch({ type: dashboardLayoutConstants.actions.TOGGLE_SIDEBAR });
  };

  useEffect(() => {
    console.log({ location });
  }, [location]);

  useEffect(() => {
    ps = new PerfectScrollbar(sidebarRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
    return ps.destroy();
  }, []);

  return (
    <div className={sidebarClassnames}>
      {/* sidebar brand */}
      <div className="sidebar-brand">
        <Logo variant={expanded ? "fullLogo" : "iconOnly"} />
        {/* <Image {...logoProps} alt="aosafrica logo" /> */}
        <button type="button" className="sidebar-brand__toggle" onClick={toggleExpanded}>
          <Icon icon={sidebarToggleIcon} iconProps={{ size: "24px", stroke: organicTheme.colors.gray900 }} />
        </button>
      </div>
      {/* sidebar top */}
      <div className={sidebarTopClassnames}>
        <div>
          <SidebarNav
            ref={sidebarRef}
            routes={DASHBOARD_ROUTE_LIST}
            filterRoutes={route => !route?.showAtBottom && !route?.ignoreChildren}
          />
          <SidebarNav
            ignoreChildren
            ref={sidebarRef}
            routes={DASHBOARD_ROUTE_LIST}
            filterRoutes={route => !route?.showAtBottom && route?.ignoreChildren}
          />
        </div>
      </div>
      {/* sidebar bottom */}
      <Box overflowY={expanded ? "auto" : " hidden"}>
        <Flex
          width={"100%"}
          maxWidth={expanded ? "241px" : "40px"}
          height={expanded ? "370px" : "40px"}
          borderRadius="1.5rem"
          backgroundColor={expanded ? "primary100" : null}
          backgroundImage={expanded ? `url(${cardBg})` : null}
          backgroundRepeat="no-repeat"
          backgroundPosition="bottom center"
          flexDirection="column"
          alignItems="center"
          gap="20px"
          px={expanded ? "1rem" : "0.85rem"}
          py="0.85rem"
        >
          <Tooltip
            content={
              expanded ? null : (
                <Text
                  as="p"
                  fontSize="1.25rem"
                  color="white"
                  fontWeight="500"
                  lineHeight="22px"
                  textAlign="center"
                  width="197px"
                >
                  Your AI-Powered Farmer Assistant
                </Text>
              )
            }
            openOnHover={!expanded}
            // onOpenChange={isOpen => {}}
            colorScheme="green"
            side="right"
            sideOffset={10}
            align={expanded ? "start" : "center"}
          >
            <Button
              mt={expanded ? "20px" : 0}
              iconOnly={expanded ? undefined : "thriveAI"}
              borderRadius="7px"
              leftIcon={expanded ? "thriveAI" : undefined}
              onClick={() => {
                if (dashboardState.showTAI) {
                  onCloseTAI();
                } else {
                  onOpenTAI();
                }
              }}
            >
              {expanded ? "Thrive AI" : null}
            </Button>
          </Tooltip>

          {expanded ? (
            <Text as="p" fontSize="1.25rem" fontWeight="500" lineHeight="22px" textAlign="center" width="197px">
              Your AI-Powered Farmer Assistant
            </Text>
          ) : null}
        </Flex>
      </Box>
      <div className={sidebarBottomClassnames}>
        <ScopedView resource="all">
          <SidebarNav ignoreChildren filterRoutes={route => route?.showAtBottom} routes={DASHBOARD_ROUTE_LIST} />
        </ScopedView>
      </div>
    </div>
  );
};

export { Sidebar };
