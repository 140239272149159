import { useMutation } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { AI_API } from "services/api/aiApiService";

export function useTAIDeepseek() {
  return useMutation({
    mutationFn: async ({ prompt }) => {
      const res = await AI_API.promptDeepseek({ prompt });
      console.log({ resData: res.data });
      return res.data;
    },
    mutationKey: [rqKeys.ai.promptDeepseek, prompt],
  });
}
