import React from "react";
import styled, { keyframes } from "styled-components";
import taiGenerating from "assets/icons/taiGenerating.svg";
import { layout } from "styled-system";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const RotatingIcon = styled.img`
  animation: ${rotate} 2s linear infinite;
  ${layout}
`;

export default function TAILoading({ size = "50px" }) {
  return <RotatingIcon src={taiGenerating} width={size} height={size} alt="" />;
}
