import farmActivity from "api/farmActivity";
import commitementFee from "api/commitmentFees";
// import { toast } from "react-toastify";
import { specialCharRemover } from "utils/v1/removeSpecialChars";
import { legacyAuthChecker } from "../../services/v1/authService";
import { handleActionError } from "services/shared/AOSErrorService";

// Ducks pattern
// actions
const ALL_FARM_SUBSCRIPTION_PAYMENTS = "ALL_FARM_SUBSCRIPTION_PAYMENTS";
const GET_ALL_FILTERED_COMMITMENT_FEES = "GET_ALL_FILTERED_COMMITMENT_FEES";
const LOAD_COMMITMENT_FEES = "LOAD_COMMITMENT_FEES";
const LOAD_SEARCH = "LOAD_SEARCH";
const RESET_PAGINATION_VALUES = "RESET_PAGINATION_VALUES";
const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
const SEARCH_TERM = "SEARCH_TERM";

// action creators
export const getCommitmentFeeWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_COMMITMENT_FEES, payload: true });

    const resData = await commitementFee.get("getAllCommitmentFeesWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_COMMITMENT_FEES,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_COMMITMENT_FEES, payload: false });
  } catch (getCommitmentFeeWithParamsError) {
    dispatch({ type: LOAD_COMMITMENT_FEES, payload: false });
    handleActionError(getCommitmentFeeWithParamsError);
  }
};
export const searchCommitmentFeeWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_SEARCH, payload: true });

    const resData = await commitementFee.get("getAllCommitmentFeesWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_COMMITMENT_FEES,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SEARCH, payload: false });
  } catch (searchCommitmentFeeWithParamsError) {
    dispatch({ type: LOAD_SEARCH, payload: false });
    handleActionError(searchCommitmentFeeWithParamsError);
  }
};

export const getSubscriptionPayments = () => async dispatch => {
  try {
    const res = await farmActivity.get("/allPaymentsHistory");

    dispatch({
      type: ALL_FARM_SUBSCRIPTION_PAYMENTS,
      payload: res.data,
    });
  } catch (getSubscriptionPaymentsError) {
    handleActionError(getSubscriptionPaymentsError);
  }
};

export const handleSearchTerm = term => dispatch => {
  const searchTerm = specialCharRemover(term);
  dispatch({
    type: SEARCH_TERM,
    payload: searchTerm,
  });
};

export const resetPaginationValues = () => dispatch => {
  dispatch({
    type: RESET_PAGINATION_VALUES,
  });
};
export const changePageSize = perPage => dispatch => {
  dispatch({
    type: CHANGE_PAGE_SIZE,
    payload: perPage,
  });
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;

  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};
export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  dispatch({
    type: HANDLE_PAGE_CHANGE,
    payload: pageNumber,
  });
  dispatch(handleOffset(pageNumber, pageSize));
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY,
    payload: arrayOfInfo,
  });
};

// reducer
const initialState = {
  allSubscriptionPayments: [],
  subscriptionPaymentsCount: 0,
  commitmentFeesData: [],
  commitmentFeePerFieldMapped: 500,
  commitmentFeesTotalAmountPaid: 0,
  equityTarget: 5500000,
  loading: false,
  loadingSearch: false,
  pageSize: 10,
  pageNumber: 1,
  offset: 0,
  info: [],
  filteredData: [],
  filteredByDate: [],
  searchTerm: "",
};

function middlewareFilterData(state, action) {
  try {
    // console.log(
    //   "============================= GET_ALL_FILTERED_AGENTS ================"
    // );
    let _data = action.payload;
    let commitmentFeesTotalAmountPaid = 0;
    commitmentFeesTotalAmountPaid = _data.length >= 1 ? Math.ceil(state.commitmentFeePerFieldMapped * _data.length) : 0;
    //for(var i = 0; i < _data.length)
    return {
      ...state,
      commitmentFeesData: _data,
      commitmentFeesTotalAmountPaid,
      pages: action.pages,
      total: action.total,
    };
  } catch (error) {
    console.log({ error });
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_FARM_SUBSCRIPTION_PAYMENTS:
      return {
        ...state,
        allSubscriptionPayments: action.payload.data,
        subscriptionPaymentsCount: action.payload.count,
      };
    case GET_ALL_FILTERED_COMMITMENT_FEES:
      return middlewareFilterData(state, action);
    case LOAD_COMMITMENT_FEES:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_SEARCH:
      return {
        ...state,
        loadingSearch: action.payload,
      };
    case CHANGE_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case HANDLE_PAGE_CHANGE:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY: {
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize);
      return { ...state, info: infoToDisplay };
    }
    case RESET_PAGINATION_VALUES:
      return { ...state, pageNumber: 1, offset: 0 };
    default:
      return state;
  }
};

// selectors
export const getAllSubscriptionPayments = state => state.legacyEquityContribution.allSubscriptionPayments;
export const getSubscriptionPaymentsCount = state => state.legacyEquityContribution.subscriptionPaymentsCount;
export const getCommitmentFeesData = state => state.legacyEquityContribution.commitmentFeesData;
export const getCommitmentFeePerFieldMapped = state => state.legacyEquityContribution.commitmentFeePerFieldMapped;
export const getCommitmentFeesTotalAmountPaid = state => state.legacyEquityContribution.commitmentFeesTotalAmountPaid;
export const getEquityTarget = state => state.legacyEquityContribution.equityTarget;
export const getLoading = state => state.legacyEquityContribution.loading;
export const getLoadingSearch = state => state.legacyEquityContribution.loadingSearch;
