import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import uniqueId from "lodash/uniqueId";
// import { hyphenate } from "utils/textUtils";
// import ErrorPage from "views/pages/404Page";
import { legacyAuthChecker } from "../services/v1/authService";
import { hyphenate } from "utils";

const PrivateRoute = ({ redirectTo, children }) => {
  let location = useLocation();
  let userAuthenticated = legacyAuthChecker();
  return userAuthenticated ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: redirectTo,
        state: { from: location },
      }}
    />
  );
};

const ignoredrenderRouteList = routeList => {
  return routeList.map(route => {
    let routeKey = uniqueId("route_");

    if (route?.index) {
      return <Route key={routeKey} index element={route.element} />;
    }

    if (route?.children) {
      return (
        <Route key={routeKey} element={route.element}>
          {ignoredrenderRouteList(route?.children)}
        </Route>
      );
    }

    return <Route key={routeKey} path={route.path} element={route.element} />;
  });
};

// const NestedRoutes = ({ routes }) => {
//   const nestedRoutes = useRoutes(routes);
//   return nestedRoutes
// };

const InnerRoutes = ({ parent, routes }) => {
  // const nestedRoutes = useRoutes(routes);

  return (
    <Routes>
      <Route element={parent.element}>
        {/* {renderRouteList(routes)} */}
        {routes.map((innerRoute, innerRouteIndex) => {
          let innerRouteKey = hyphenate(["inner", innerRoute.name, "route", innerRouteIndex]);

          if (innerRoute?.index) {
            return <Route key={innerRouteKey} index element={innerRoute.element} />;
          }
          if (innerRoute?.children) {
            return (
              <Route
                path={innerRoute.path}
                key={innerRouteKey}
                element={
                  innerRoute?.children ? (
                    <InnerRoutes parent={innerRoute} routes={innerRoute.children} />
                  ) : (
                    innerRoute.element
                  )
                }
              >
                {innerRoute.children.map((nestedRoute, nestedRouteIndex) => {
                  let nestedRouteKey = hyphenate(["inner", innerRoute.name, "nested-route", nestedRouteIndex]);

                  if (nestedRoute?.index) {
                    return <Route key={nestedRouteKey} index element={nestedRoute.element} />;
                  }
                  return <Route key={nestedRouteKey} path={nestedRoute.path} element={nestedRoute.element} />;
                })}
              </Route>
            );
          }
          return <Route key={innerRouteKey} path={innerRoute.path} element={innerRoute.element} />;
        })}
      </Route>
    </Routes>
  );
};

export { PrivateRoute };
export default InnerRoutes;
