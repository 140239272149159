import { useDashboardData } from "hooks";
import { useTAIDeepseek } from "hooks/app/TAI";
import { Button, buttonConstants, Flex, TextArea } from "organic";
import React from "react";
import TAILoading from "./TAILoading";

export const TAIInput = () => {
  const { dashboardState, setTAIPrompt, setTAIView, setCurrentTAIConversation } = useDashboardData();
  const { mutate, isLoading, isPending, isError } = useTAIDeepseek();

  const handlePromptChange = event => {
    const { key, shiftKey } = event;

    // Detect Shift + Enter combo
    if (key === "Enter" && shiftKey) {
      event.preventDefault();
      return;
    }
    setTAIPrompt?.(event.target.value);
  };

  const handlePromptKeyDown = event => {
    if (isPending || isLoading) {
      return;
    }

    const { key, shiftKey } = event;

    // Detect Shift + Enter combo
    if (key === "Enter" && shiftKey) {
      event.preventDefault();
      setTAIPrompt(dashboardState.currentTAIPrompt + "\n");
      return;
    }

    if (key === "Enter" && !isPending && !isLoading) {
      event.preventDefault();
      handleSend();
      return;
    }
  };

  const handleSend = () => {
    if (dashboardState.currentTAIView !== "conversation") {
      setTAIView("conversation");
    }

    mutate(
      { prompt: dashboardState.currentTAIPrompt },
      {
        onSuccess(data) {
          setCurrentTAIConversation([{ prompt: dashboardState.currentTAIPrompt, response: data }]);
          setTAIPrompt("");
        },
        onError(error) {
          console.log({ error });
        },
      }
    );
  };

  return (
    <TextArea
      value={dashboardState.currentTAIPrompt}
      onKeyDown={handlePromptKeyDown}
      onBlur={handlePromptKeyDown}
      isReadOnly={isPending || isLoading}
      isInvalid={isError}
      containerStyles={{ alignItems: "flex-end" }}
      isRounded
      leftAddon={
        <Flex>
          <Button isDisabled size="xs" variant={buttonConstants.variants.UNSTYLED} iconOnly="mic" iconSize="18px" />
          <Button
            isDisabled
            size="xs"
            variant={buttonConstants.variants.UNSTYLED}
            iconOnly="plus-circle"
            iconSize="18px"
          />
        </Flex>
      }
      onChange={handlePromptChange}
      rightAddon={
        <Flex>
          {isLoading || isPending ? <TAILoading size="16px" /> : null}

          <Button
            onClick={handleSend}
            size="xs"
            variant={buttonConstants.variants.UNSTYLED}
            iconOnly="send"
            iconSize="18px"
            isDisabled={isPending || isLoading}
          />
        </Flex>
      }
      position="absolute"
      placeholder="Ask Thrive AI anything"
    />
  );
};
