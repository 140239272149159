export const dashboardLayoutConstants = {
  actions: {
    EXPAND_SIDEBAR: "EXPAND_SIDEBAR",
    COLLAPSE_SIDEBAR: "COLLAPSE_SIDEBAR",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    CURRENT_PAGE_TAB: "CURRENT_PAGE_TAB",
    SET_DATA: "SET_DATA",
    SHOW_TAI: "SHOW_TAI",
    HIDE_TAI: "HIDE_TAI",
    TOGGLE_TAI_ACTIVE: "TOGGLE_TAI_ACTIVE",
    SET_AI_PROMPT: "SET_AI_PROMPT",
    SET_AI_VIEW: "SET_AI_VIEW",
    SET_CURRENT_CONVERSATION: "SET_CURRENT_CONVERSATION",
  },
};

export const setCurrentPageTabId = tabId => dispatch => {
  dispatch({ type: dashboardLayoutConstants.actions.CURRENT_PAGE_TAB, payload: tabId });
};
export const expandSidebar = () => dispatch => {
  dispatch({ type: dashboardLayoutConstants.actions.EXPAND_SIDEBAR });
};
export const collpaseSidebar = () => dispatch => {
  dispatch({ type: dashboardLayoutConstants.actions.COLLAPSE_SIDEBAR });
};
export const toggleSidebar = () => dispatch => {
  dispatch({ type: dashboardLayoutConstants.actions.TOGGLE_SIDEBAR });
};

export const setData = dataSlice => dispatch => {
  dispatch({ type: dashboardLayoutConstants.actions.SET_DATA, payload: dataSlice });
};

const initialDashboardState = {
  sidebarExpanded: true,
  currentPageTabId: null,
  // arbitrary data - you can store anything here
  // to make it gloablly available accross dahsboard pages
  data: {},
  showTAI: false,
  isTAIActive: false,
  currentTAIPrompt: "",
  isTAITyping: false,
  currentTAIView: "history", // 'history' | 'conversation'
  currentTAIConversation: [],
  isTAIGenerating: false,
};

export default function dashboardLayoutReducer(state = initialDashboardState, action) {
  const { actions } = dashboardLayoutConstants;
  switch (action.type) {
    case actions.EXPAND_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: true,
      };
    }
    case actions.COLLAPSE_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: false,
      };
    }
    case actions.TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: !state.sidebarExpanded,
      };
    }
    case actions.TOGGLE_TAI_ACTIVE: {
      return {
        ...state,
        data: { ...state.data, isTAIActive: !state.data.isTAIActive },
      };
    }
    case actions.SHOW_TAI: {
      return {
        ...state,
        showTAI: true,
        sidebarExpanded: false,
      };
    }
    case actions.HIDE_TAI: {
      return {
        ...state,
        showTAI: false,
        sidebarExpanded: true,
      };
    }
    case actions.SET_AI_PROMPT: {
      return {
        ...state,
        currentTAIPrompt: action.payload,
      };
    }
    case actions.SET_AI_VIEW: {
      return {
        ...state,
        currentTAIView: action.payload,
      };
    }
    case actions.SET_CURRENT_CONVERSATION: {
      return {
        ...state,
        currentTAIConversation: [...state.currentTAIConversation, ...action.payload],
      };
    }
    case actions.CURRENT_PAGE_TAB: {
      return {
        ...state,
        currentPageTabId: action.payload,
      };
    }
    case actions.SET_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export const getSidebarExpanded = state => state.legacyDashboard.sidebarExpanded;
export const getCurrentPageTabId = state => state.legacyDashboard.currentPageTabId;
export const getDashboardData = state => state.legacyDashboard.data;
export const getDashboardState = state => state.legacyDashboard;
