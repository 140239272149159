import React from "react";
import PropTypes from "prop-types";
import { Flex } from "organic";

const FormGridRowPropTypes = {
  children: PropTypes.node,
  check: PropTypes.bool,
};

const LegacyFormGridRow = props => {
  const { children, check, className, style } = props;

  let classNames = `ca-font-regular ${className}`;
  return (
    <Flex className={classNames} check={check} style={style} row>
      {children}
    </Flex>
  );
};

LegacyFormGridRow.propTypes = FormGridRowPropTypes;

export default LegacyFormGridRow;
