import { Badge } from "organic";
import React from "react";

const CustomBadge = ({ children, color, ...restProps }) => {
  return (
    <Badge color={color} {...restProps}>
      {children}
    </Badge>
  );
};

export default CustomBadge;
