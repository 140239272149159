import { combineReducers } from "redux";
// legacy reducers
import legacyAgentReducer from "./v1/agents";
import legacyAuthReducer from "./v1/auth";
import legacyCommonReducer from "./v1/common";
import legacyCropReducer from "./v1/cropsAndInput";
import legacyEquityContributionReducer from "./v1/equityContribution";
import legacyFarmActivityReducer from "./v1/farmActivity";
import legacyFarmerClearanceReducer from "./v1/farmerClearance";
import legacyFarmerReducer from "./v1/farmers";
import legacyFarmMappingReducer from "./v1/farmMapping";
import legacyFarmMonitoringReducer from "./v1/farmMonitoring";
import legacyFilterReducer from "./v1/filter";
import legacyFinanceReducer from "./v1/finance";
import legacyHarvestReducer from "./v1/harvest";
import legacyInboundReducer from "./v1/inboundService";
import legacyInputDistributionReducer from "./v1/inputDistribution";
import legacyLanguageReducer from "./v1/language";
import legacyOverviewReducer from "./v1/overview";
import legacyRegionReducer from "./v1/regions";
import legacySeasonReducer from "./v1/season";
import legacySubscriptionReducer from "./v1/subscription";
import legacySurveyReducer from "./v1/survey";
import legacyWarehouseReducer from "./v1/warehouse";
import legacyWeatherReducer from "./v1/weather";
import legacyZoneReducer from "./v1/zones";
import legacyQuestionnaire from "./v1/questionnaire";
import legacyContractorsReducer from "./v1/contractors";
import legacyRoleReducer from "./v1/role";
import legacyFarmerSeasonReducer from "./v1/farmerSeason";
import legacyDashboardReducer from "./v1/dashboard";
import legacyFarmerPaymentReducer from "./v1/farmerPayment";
import legacyOrganizationReducer from "./v1/organization";
import legacyBulkSmsReducer from "./v1/bulkSms";
import legacyRiskManagementReducer from "./v1/riskManagement";

// new reducers
import auth from "./auth";

const reduxReducer = combineReducers({
  legacyAgent: legacyAgentReducer,
  legacyAuth: legacyAuthReducer,
  legacyCommon: legacyCommonReducer,
  legacyContractors: legacyContractorsReducer,
  legacyCrops: legacyCropReducer,
  legacyDashboard: legacyDashboardReducer,
  legacyEquityContribution: legacyEquityContributionReducer,
  legacyFarmActivity: legacyFarmActivityReducer,
  legacyFarmer: legacyFarmerReducer,
  legacyFarmerClearance: legacyFarmerClearanceReducer,
  legacyFarmerSeason: legacyFarmerSeasonReducer,
  legacyFarmMapping: legacyFarmMappingReducer,
  legacyFarmMonitoring: legacyFarmMonitoringReducer,
  legacyFilter: legacyFilterReducer,
  legacyFinance: legacyFinanceReducer,
  legacyHarvest: legacyHarvestReducer,
  legacyInboundService: legacyInboundReducer,
  legacyInputDistribution: legacyInputDistributionReducer,
  legacyLanguage: legacyLanguageReducer,
  legacyOverview: legacyOverviewReducer,
  legacyQuestionnaire: legacyQuestionnaire,
  legacyRegion: legacyRegionReducer,
  legacyRole: legacyRoleReducer,
  legacySeason: legacySeasonReducer,
  legacySubscription: legacySubscriptionReducer,
  legacySurvey: legacySurveyReducer,
  legacyWarehouse: legacyWarehouseReducer,
  legacyWeather: legacyWeatherReducer,
  legacyZone: legacyZoneReducer,
  legacyFarmerPayment: legacyFarmerPaymentReducer,
  legacyOrganization: legacyOrganizationReducer,
  legacyBulkSms: legacyBulkSmsReducer,
  legacyRiskManagement: legacyRiskManagementReducer,
});

const RESET_STORE = "RESET_STORE";

export const resetStore = () => ({
  type: RESET_STORE,
});

export const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return reduxReducer(undefined, action); // Reset state to initial values
  }
  return reduxReducer(state, action);
};

export const toolkitReducer = combineReducers({
  auth,
});
