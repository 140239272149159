import { legacyAuthChecker } from "../../services/v1/authService";
import { toast } from "react-toastify";
import admin2 from "api/admin";
import warehouse from "api/warehouse";
import { handleActionError } from "services/shared/AOSErrorService";

// Ducks pattern
// actions
// const CREATE_WAREHOUSE = "CREATE_WAREHOUSE";
const TOGGLE_WAREHOUSE_STATE = "TOGGLE_WAREHOUSE_STATE";
const GET_ALL_WAREHOUSE = "GET_ALL_WAREHOUSE";
const LOAD_ALL_WAREHOUSE = "LOAD_ALL_WAREHOUSE";
// const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
// const CLEAR_WAREHOUSE_FORM = "CLEAR_WAREHOUSE_FORM";
// const ADD_WAREHOUSE = "ADD_WAREHOUSE";
const HANDLE_SEARCH_WAREHOUSE = "HANDLE_SEARCH_WAREHOUSE";

// action creators

export const getAllWarehouses = () => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: LOAD_ALL_WAREHOUSE, payload: true });
    const warehouseData = await admin2.get("/warehouse");

    dispatch({
      type: GET_ALL_WAREHOUSE,
      payload: warehouseData.data.data,
    });
    dispatch({ type: LOAD_ALL_WAREHOUSE, payload: false });
  } catch (getAllWarehousesError) {
    dispatch({ type: LOAD_ALL_WAREHOUSE, payload: false });
    handleActionError({ getAllWarehousesError });
  }
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;

  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};
//
/*
 *
 * @params: <Object> date: (startdate, endDate), season: <String>,
 * region: <String>, zone: <String>
 * @return
 **/

// function for filter
export const getAllWarehouseWithParams = params => async dispatch => {
  try {
    legacyAuthChecker();
    dispatch({ type: GET_ALL_WAREHOUSE, payload: true });

    const warehouseData = await admin2.get("getAllWarehouseWithParams", { params });

    dispatch({
      type: GET_ALL_WAREHOUSE,
      payload: warehouseData.data.data,
      pages: warehouseData.data.pages,
      total: warehouseData.data.total,
    });

    dispatch({ type: GET_ALL_WAREHOUSE, payload: false });
  } catch (getAllWarehouseWithParamsError) {
    dispatch({ type: GET_ALL_WAREHOUSE, payload: false });
    handleActionError({ getAllWarehouseWithParamsError });
  }
};

export const getPaginatedFarmersBySeason =
  (page_number, per_page, season, { ...options }) =>
  dispatch => {
    legacyAuthChecker();
    dispatch({
      type: GET_ALL_WAREHOUSE,
      payload: true,
    });
    const params = {
      page_number,
      per_page,
      season,
    };
    // console.log('hit getPFBS')
    if (options && options.showAllSeasons) {
      // console.log(options)
      admin2
        .get("/", { page_number, per_page })
        .then(farmerData => {
          dispatch({
            type: GET_ALL_WAREHOUSE,
            payload: farmerData.data.data,
            pages: farmerData.data.pages,
            total: farmerData.data.total,
          });

          dispatch({
            type: GET_ALL_WAREHOUSE,
            payload: false,
          });
        })
        .catch(__err => {
          dispatch({
            type: GET_ALL_WAREHOUSE,
            payload: false,
          });
          // if (err.response.status === 401) {
          //   return toast.error(err.response.data.message);
          // }
          toast.error("Something went wrong fetching Farmers, try refreshing the page");
        });
    } else {
      admin2
        .get("/currentSeason", { params })
        .then(farmerData => {
          console.log("Farmer Data here", {
            farmerData,
            total: farmerData.data.total,
          });
          dispatch({
            type: GET_ALL_WAREHOUSE,
            payload: farmerData.data.data,
            pages: farmerData.data.pages,
            total: farmerData.data.total,
          });

          dispatch({ type: GET_ALL_WAREHOUSE, payload: false });
        })
        .catch(__err => {
          // if (err.response.status === 401) {
          //   return toast.error(err.response.data.message);
          // }
          toast.error("Something went wrong fetching Farmers, try refreshing the page");
          dispatch({ type: GET_ALL_WAREHOUSE, payload: false });
        });
    }
  };

export const createWarehouse =
  (values, { formikOptions, modal }) =>
  async dispatch => {
    try {
      await warehouse.post("createWarehouse", values);

      toast.success("Store created Sucessfully");
      formikOptions.setSubmitting(false);
      if (modal) {
        modal.hideModal();
      }
      return dispatch(getAllWarehouses());
    } catch (createWarehouseErr) {
      if (createWarehouseErr?.isAxiosError) toast(createWarehouseErr.response.data.message);
      formikOptions.setSubmitting(false);
    }
  };

export const updateWarehouse =
  (__values, { __setSubmitting, __setErrors }) =>
  __dispatch => {};

export const assignStoreMangerToWarehouse = (values, config) => dispatch => {
  dispatch({ type: LOAD_ALL_WAREHOUSE, payload: true });
  const hasModal = config?.modal || false;
  const params = { ...values };
  return warehouse
    .put("addStoreManagerToWarehouse", undefined, { params })
    .then(res => {
      if (res.status === 200) {
        toast.success("Store Manager Assinged Successfully");
      }
      dispatch({ type: LOAD_ALL_WAREHOUSE, payload: false });
      if (hasModal) config.modal.hideModal();
      return dispatch(getAllWarehouses());
    })
    .catch(err => {
      if (err?.isAxiosError) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
      dispatch({ type: LOAD_ALL_WAREHOUSE, payload: false });
    });
};
export const handleSearchStores = searchTerm => dispatch => {
  dispatch({ type: HANDLE_SEARCH_WAREHOUSE, payload: searchTerm });
};
function middlewareSearchWarehouses(state, action) {
  let _dataCopy = state.warehouseData;
  if (action.payload === "") {
    // console.time("warehouseSearchEmpty");

    // console.timeEnd("warehouseSearchEmpty");
    return { ...state, warehouseData: state.warehouseSearchData };
  } else {
    // console.time("warehouseSearchEmpty");
    let filteredData = _dataCopy.filter(
      warehouse => warehouse?.store?.ta_number?.match(action.payload) || warehouse?.store?.name?.match(action.payload)
    );
    // console.timeEnd("warehouseSearchEmpty");
    return { ...state, warehouseData: filteredData };
  }
}
//reducer
const initialState = {
  warehouseActive: false,
  warehouseData: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_WAREHOUSE_STATE: {
      const { warehouseActive } = state;
      return { ...state, warehouseActive: !warehouseActive };
    }
    case LOAD_ALL_WAREHOUSE:
      return { ...state, loading: action.payload };
    case GET_ALL_WAREHOUSE:
      return {
        ...state,
        warehouseData: action.payload,
        warehouseSearchData: action.payload,
      };
    case HANDLE_PAGE_CHANGE:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case HANDLE_SEARCH_WAREHOUSE:
      return middlewareSearchWarehouses(state, action);
    case SET_INFO_TO_DISPLAY: {
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize);
      return { ...state, info: infoToDisplay };
    }
    default:
      return state;
  }
};

// selector functions
export const getWarehouseData = state => state.legacyWarehouse.warehouseData;
export const getLoadingWarehouse = state => state.legacyWarehouse.loading;
