import { apiConstants } from "constants/apiConstants";
import { axiosService } from "services/fetchService";
import { getApiUrl } from "utils";

export class AI_API {
  static async promptDeepseek(data = { prompt: "" }, __config) {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.ai, path: "/prompt/deepseek" });
    return await axiosService({ method: "POST", url, data });
  }
  static async promptAI(data = { prompt: "" }, __config) {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.ai, path: "/prompt" });
    return await axiosService({ method: "POST", url, data });
  }
  static async fetchConversations(__config) {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.aiHistory, path: "/" });
    return await axiosService({ method: "GET", url });
  }
}
