import history from "../../history";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { removeUserScope } from "./rolesAndPermissionsService";
import { sessionStorageKeys } from "constants/storageConstants";

const TOKEN = "token";
const REFRESH = "refresh";
const ORG = "org";
const ORG_NAME = "orgName";
const SENDER_ID = "senderId";

const SESSION_EXPIRED_TOAST = "sessionExpiredMessage";

export const legacyAuthChecker = () => {
  return sessionStorage.getItem(sessionStorageKeys.TOKEN) ? true : false;
};

export const setToken = token => {
  sessionStorage.setItem(TOKEN, token);
};
export const setRefresh = token => {
  sessionStorage.setItem(REFRESH, token);
};
export const setOrg = org => {
  sessionStorage.setItem(ORG, org);
};
export const setOrgName = name => {
  sessionStorage.setItem(ORG_NAME, name);
};
export const setSenderId = data => {
  sessionStorage.setItem(SENDER_ID, JSON.stringify(data));
};

export const removeToken = () => {
  return sessionStorage.removeItem(TOKEN);
};
export const removeRefresh = () => {
  return sessionStorage.removeItem(REFRESH);
};
export const removeOrg = () => {
  return sessionStorage.removeItem(ORG);
};
export const removeOrgName = () => {
  return sessionStorage.removeItem(ORG_NAME);
};
export const removeSenderId = () => {
  return sessionStorage.removeItem(SENDER_ID);
};

export const getOrg = () => {
  return sessionStorage.getItem(ORG);
};
export const getToken = () => {
  return sessionStorage.getItem(TOKEN);
};
export const getRefresh = () => {
  return sessionStorage.getItem(REFRESH);
};
export const getOrgName = () => {
  return sessionStorage.getItem(ORG_NAME);
};
export const getSenderId = () => {
  return JSON.parse?.(sessionStorage.getItem(SENDER_ID)) || null;
};

export const unauthorized = async err => {
  if (!err.response) {
    removeToken();
    removeRefresh();
    removeOrg();
    removeUserScope();
    removeOrgName();
    removeSenderId();
    sessionStorage.setItem("logout", Date.now());
    history.push("/auth/login");
    toast.error(err.message);
    return;
  }
  const { status } = err.response;
  if (status === 401) {
    removeToken();
    removeRefresh();
    removeOrg();
    removeUserScope();
    removeOrgName();
    removeSenderId();
    sessionStorage.setItem("logout", Date.now());
    history.push("/auth/login");
    if (err?.response?.data?.message && err?.response?.data?.message?.includes("TokenExpiredError")) {
      toast.error("Your session has expired, kindly log in to continue", { toastId: SESSION_EXPIRED_TOAST });
      return;
    }
    toast.error(err.response.data.message);
  }
};

export const tokenExpired = err => {
  if (err?.response?.data?.message && err?.response?.data?.message?.includes("TokenExpiredError")) {
    removeToken();
    removeRefresh();
    removeOrg();
    removeUserScope();
    removeOrgName();
    removeSenderId();
    sessionStorage.setItem("logout", Date.now());
    history.push("/auth/login");
    toast.error("Your session has expired, kindly log in to continue", { toastId: SESSION_EXPIRED_TOAST });
    return;
  }
  return;
};

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem(TOKEN);
    if (jwt) {
      let decodedUser = jwtDecode(jwt);

      return decodedUser;
    }
    legacySignOut();
    return null;
  } catch (ex) {
    return null;
  }
}

export const isAuthorized = () => {
  const user = getCurrentUser();
  return user.role !== "superAdmin";
};

export const legacySignOut = () => {
  removeToken();
  removeRefresh();
  removeOrg();
  removeUserScope();
  removeOrgName();
  removeSenderId();
  history.push("/auth/login");
  // toast reason for log out instead
  // toast.error("Goodbye!");
};

export default {
  authChecker: legacyAuthChecker,
  getCurrentUser,
  getOrg,
  getRefresh,
  getToken,
  isAuthorized,
  removeOrg,
  removeRefresh,
  removeToken,
  setOrg,
  setRefresh,
  setToken,
  setSenderId,
  signOut: legacySignOut,
  unauthorized,
  tokenExpired,
};
