import { useQueryClient } from "@tanstack/react-query";
import { localStorageKeys } from "constants/storageConstants";
import { resetStore } from "features";
import { useCallbackRef } from "hooks/ui/useCallbackRef";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = useCallbackRef(() => {
    // clear sessionStorage
    sessionStorage.clear();

    localStorage.setItem(localStorageKeys.LOGOUT, Date.now());
    // Cancel all active queries
    queryClient.cancelQueries();

    // Remove all queries from cache
    queryClient.clear();

    dispatch(resetStore());

    toast.info("See you soon!");

    // Navigate to the auth route and replace history
    navigate("/auth", { replace: true });
  }, [dispatch, navigate, queryClient]);

  return logout;
}
