import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import FarmerPayment_API from "services/api/farmerPaymentApiService";
import { AOSClientError } from "services/shared/AOSErrorService";

export function useInitiateFarmerPayment() {
  return useMutation({
    mutationFn: values => {
      return FarmerPayment_API.initiaiteFarmerPayment(values);
    },
    onSuccess: data => {
      toast.success("Payment initiated successfully");
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Failed to initiaite farmer payment, kindly try again");
      bug.report(error);
    },
  });
}
